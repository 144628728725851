@import '_styles/core.scss';

.timeFrameSwitcher {
  border: none;
  cursor: pointer;
}

.container {
  padding-left: 1.5 * $space;
  position: relative;

  @include media('<phone') {
    padding-left: 1.5 * $space;
  }
}

.timeFrame {
  @include FontBold;
  @include font-small;
  text-decoration: underline;
  padding-right: $space;
  padding-top: $space;
  position: relative;

  @include media('<phone') {
    @include font-xSmall;
  }
}

.time-frame-modal {
  margin-top: 1.25 * $space;
  width: 157px;
  background: $white;
  position: absolute;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 3 * $space;
  overflow: hidden;
  right: 25px;
  z-index: $zindex-modal;

  @include media('>=phone', '<desktop') {
    right: 0;
  }

  @include media('<phone') {
    width: 100%;
    right: 0;
    padding: (3 * $space) (2.5 * $space);
  }
}

.arrowBlack {
  fill: $textblackprimary;
  transform: rotate(180deg);
}

.arrowBlackReversed {
  fill: $textblackprimary;
  transform: rotate(0deg);
}

.radio {
  @include font-small;
  input {
    &:checked + span::before {
      border-color: #008a03;
      background-color: $white;
      box-shadow: inset 0 0 0 3px #008a03;
    }
  }
  span {
    &:focus-within,
    &:focus,
    &:hover {
      &::before {
        border-color: #008a03;
        background-color: $white;
        box-shadow: inset 0 0 0 3px #008a03;
      }
    }
  }
}
