@import './config.color.scss';
@import './config.space.scss';
@import './config.media-queries.scss';
@import './config.grid.scss';
@import './config.typography.scss';
@import './config.box-shadow.scss';
@import './config.z-index.scss';
@import './config.popovers.scss';
@import './config.tooltips.scss';

// Container
$container-max: 1136px;

// Border Radius
$border-radius: 4px;
$border-radius-lg: 8px;

// Pages
$page-background: #e5e5e5;
$page-header-box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.1);

// Transitions
$transition-duration-quick: 0.1s;
$transition-duration: 0.2s;
$transition-duration-slow: 0.4s;

// Pages
$page-background: #e5e5e5;
$page-header-box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.1);

@mixin transition(
  $property: all,
  $duration: $transition-duration,
  $easing: linear
) {
  transition: $property $duration $easing;
}

@import 'include-media/dist/include-media';
