@import '_styles/core.scss';

.container {
  display: flex;
  flex-direction: column;
}

.header {
  @include FontSemiBold;
  @include font-small;
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  color: $gray60;
  padding-bottom: $space;
}

.empty {
  width: 100%;
  height: 244px;
  background: $gray10;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
