.input-container {
  display: grid;
  grid-template-columns: 1fr 30px;
  padding-top: 1rem;
}

.delete-button {
  align-self: center;
  justify-self: center;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}

.menu-items {
  padding-top: 1rem;
}

.add {
  padding-top: 0.5rem;

  button {
    padding: 0;
    border-width: 0;
  }
}
