@import '_styles/core.scss';

.header-metrics-wrapper:nth-child(1) {
  margin-right: 4 * $space;
}

.header-metrics-wrapper:nth-child(2) {
  margin-right: 5 * $space;
}

.header-metrics-wrapper {
  span {
    @include font-xxxLarge;
    @include FontSemiBold;
    cursor: pointer;
  }

  div {
    @include font-small;
  }
}

.page-body {
  display: grid;
  grid-template-columns: auto (49.25 * $space);
  grid-column-gap: 4 * $space;

  .results-block {
    .filter-block {
      display: flex;
      justify-content: space-between;
      margin-bottom: 3 * $space;

      .search-wrapper {
        position: relative;

        .search-text,
        .search-text:focus,
        .search-text:focus-visible {
          border: none;
          outline: none;
          background: transparent;
          border-bottom: 1px solid #000000;
          width: 28 * $space;
        }

        svg {
          position: absolute;
          bottom: 0.75 * $space;
          right: 0.75 * $space;
        }

        .active + svg {
          display: none;
        }
      }

      .right-filter {
        display: flex;

        .status-filter {
          margin-right: 2 * $space;
        }

        select,
        select:focus,
        select:focus-visible {
          @include FontBold;
          border: none;
          outline: none;
          text-align: center;
        }
      }
    }

    .award-track-card {
      margin-bottom: 2 * $space;
    }

    .loader {
      height: 9.25 * $space;
    }
  }

  .info-block {
    height: 45.25 * $space;
    margin-top: 7 * $space;
    border-radius: $border-radius;
    box-shadow: $card-box-shadow;
  }
}
