.container {
  h2,
  h3,
  section h3 {
    font-size: var(--r-font-size-300);
    font-weight: var(--r-font-weight-semibold);
  }
}

.cta-block {
  display: flex;
  a {
    margin-left: 0.5rem;
  }
}
