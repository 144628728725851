@import '_styles/core.scss';

.tr-popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: $zindex-popover;
  display: block;
  max-width: $popover-max-width;
  word-wrap: break-word;
  background-color: white;
  background-clip: padding-box;
  border: $popover-border-width solid $popover-border-color;
  box-shadow: $box-shadow;
  border-radius: $popover-border-radius;

  .arrow {
    position: absolute;
    display: block;
    width: 10px;
    height: 10px;
    margin: 0 $border-radius;

    &::before,
    &::after {
      position: absolute;
      display: block;
      content: '';
      border-color: transparent;
      border-style: solid;
    }
  }

  button.close {
    @include dismissButton;
  }
}

.bs-popover-top {
  margin-bottom: $popover-arrow-height + $popover-arrow-offset;

  > .arrow {
    bottom: calc((#{$popover-arrow-height} + #{$popover-border-width}) * -1);

    &::before {
      bottom: 0;
      border-width: $popover-arrow-height ($popover-arrow-width / 2) 0;
      border-top-color: $popover-arrow-outer-color;
    }

    &::after {
      bottom: $popover-border-width;
      border-width: $popover-arrow-height ($popover-arrow-width / 2) 0;
      border-top-color: $popover-arrow-color;
    }
  }
}

.bs-popover-right {
  margin-left: $popover-arrow-height + $popover-arrow-offset;

  > .arrow {
    left: calc((#{$popover-arrow-height} + #{$popover-border-width}) * -1);
    width: $popover-arrow-height;
    height: $popover-arrow-width;
    margin: $border-radius 0; // make sure the arrow does not touch the popover's rounded corners

    &::before {
      left: 0;
      border-width: ($popover-arrow-width / 2) $popover-arrow-height
        ($popover-arrow-width / 2) 0;
      border-right-color: $popover-arrow-outer-color;
    }

    &::after {
      left: $popover-border-width;
      border-width: ($popover-arrow-width / 2) $popover-arrow-height
        ($popover-arrow-width / 2) 0;
      border-right-color: $popover-arrow-color;
    }
  }
}

.bs-popover-bottom {
  margin-top: $popover-arrow-height + $popover-arrow-offset;

  > .arrow {
    top: calc((#{$popover-arrow-height} + #{$popover-border-width}) * -1);

    &::before {
      top: 0;
      border-width: 0 ($popover-arrow-width / 2) $popover-arrow-height
        ($popover-arrow-width / 2);
      border-bottom-color: $popover-arrow-outer-color;
    }

    &::after {
      top: $popover-border-width;
      border-width: 0 ($popover-arrow-width / 2) $popover-arrow-height
        ($popover-arrow-width / 2);
      border-bottom-color: $popover-arrow-color;
    }
  }

  // This will remove the popover-header's border just below the arrow
  .tr-popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: $popover-arrow-width;
    margin-left: -$popover-arrow-width / 2;
    content: '';
    border-bottom: $popover-border-width solid $popover-header-bg;
  }
}

.bs-popover-left {
  margin-right: $popover-arrow-height + $popover-arrow-offset;

  > .arrow {
    right: calc((#{$popover-arrow-height} + #{$popover-border-width}) * -1);
    width: $popover-arrow-height;
    height: $popover-arrow-width;
    margin: $border-radius 0; // make sure the arrow does not touch the popover's rounded corners

    &::before {
      right: 0;
      border-width: ($popover-arrow-width / 2) 0 ($popover-arrow-width / 2)
        $popover-arrow-height;
      border-left-color: $popover-arrow-outer-color;
    }

    &::after {
      right: $popover-border-width;
      border-width: ($popover-arrow-width / 2) 0 ($popover-arrow-width / 2)
        $popover-arrow-height;
      border-left-color: $popover-arrow-color;
    }
  }
}

.bs-popover-auto {
  &[x-placement^='top'] {
    @extend .bs-popover-top;
  }
  &[x-placement^='right'] {
    @extend .bs-popover-right;
  }
  &[x-placement^='bottom'] {
    @extend .bs-popover-bottom;
  }
  &[x-placement^='left'] {
    @extend .bs-popover-left;
  }
}

// Offset the popover to account for the popover arrow
.tr-popover-header {
  padding: $popover-header-padding-y $popover-header-padding-x;
  margin: 0; // Reset the default from Reboot
  color: $popover-header-color;
  background-color: $popover-header-bg;
  border-bottom: $popover-border-width solid darken($popover-header-bg, 5%);
  border-top-left-radius: $popover-border-radius;
  border-top-right-radius: $popover-border-radius;

  // Because the bootstrap react component we are using adds an .h3
  // class to the header, we need to forcefully change the styles
  // with the !important declaration. While this is not ideal, we've
  // decided to roll with it.
  &.h3 {
    font-family: 'Source Sans Pro', sans-serif !important;
    font-size: 16px !important;
    line-height: 1.5 !important;
    font-weight: bold !important;
  }

  &:empty {
    display: none;
  }
}

.tr-popover-body {
  padding: $popover-body-padding-y $popover-body-padding-x;
  color: $popover-body-color;

  p:last-child {
    margin-bottom: 0;
  }
}
