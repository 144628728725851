@import '_styles/core.scss';

.body {
  padding: 2rem;
}

.container {
  border: 1px solid var(--r-color-gray-30);
  border-radius: 0.5rem;
}

.actions {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.table-wrap {
  display: flex;
  justify-content: center;
}

.table-wrap-empty {
  min-height: 26.375rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.toolbar {
  border-bottom: 1px solid var(--r-color-gray-30);
  padding: 1.5rem;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
}

.leads-quantity {
  font-size: var(--r-font-size-300);
  line-height: var(--r-font-size-300);
  font-weight: var(--r-font-weight-semibold);
  margin-bottom: 1.5rem;
}

.table {
  th {
    width: 20%;
  }
}

.hidden {
  th {
    width: 25%;
  }
}
