@import '../../_styles/core.scss';

.container {
  padding: $space * 4;
  max-width: 664px;
  font-size: 16px;
}

.inputGroup {
  position: relative;

  input {
    &::placeholder {
      color: var(--r-color-gray-80);
    }
  }
}

.paragraph {
  margin: $space * 3 0;
  color: var(--r-color-gray-100);
}

.icon {
  position: absolute;
  top: 73%;
  transform: translateY(-50%);
  right: 0.5rem;
}

.success {
  color: var(--r-color-positive);
}

.error {
  color: var(--r-color-negative);
}

.button {
  margin-top: $space * 2;
}

.notification {
  margin-bottom: $space * 4;
}
.modalButtons {
  display: flex;
  justify-content: space-around;
  padding-top: 4rem;

  button {
    width: 45%;
    justify-content: center;
  }
}

.dialog {
  max-width: 472px !important;
}

.header {
  background: $white;
  align-items: flex-start;
}

.body {
  text-align: center;
  padding-top: 0;
}

.title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;

  h2 {
    padding: 2rem 0 1rem;
  }
}

.backgroundLight {
  width: 84px;
  height: 84px;
  background: $negativefade;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.backgroundDark {
  width: 52px;
  height: 52px;
  background: $mediumred;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $contrastred;
}
