// POPOVER

$popover-body-color: $textblack;

$popover-bg: $white !default;
$popover-max-width: 276px !default;
$popover-border-color: $gray40;
$popover-border-radius: 8px;
$popover-border-width: 1px;
$popover-box-shadow: $box-shadow !default;
$popover-arrow-offset: 4px;

$popover-header-bg: $gray10;
$popover-header-color: $textblack;
$popover-header-padding-y: $space !default;
$popover-header-padding-x: $space * 2 !default;

$popover-body-color: $body-color !default;
$popover-body-padding-y: $space * 2;
$popover-body-padding-x: $space * 2;

$popover-arrow-width: $space * 1.75;
$popover-arrow-height: $space * 1;
$popover-arrow-color: $popover-bg !default;

$popover-arrow-outer-color: $popover-border-color !default;
