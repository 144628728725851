@import '_styles/core.scss';

.banner {
  background-color: $bluefade;
  padding: $space (4 * $space);

  @include media('>phone', '<=tablet') {
    padding: $space (3 * $space);
  }

  a {
    @include FontBold;
    color: $xxdarkTRblue;
    text-decoration: underline;

    &:focus,
    &:hover {
      text-decoration: none;
    }
  }
}
