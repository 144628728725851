@import '_styles/core.scss';

.backdrop:global(.trModal-backdrop) {
  display: block;
}

.modal {
  .dialog:global(.modal-dialog) {
    min-height: calc(100vh - 3.5rem); // removing margins from modal styles
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .header {
    background-color: transparent;
    padding-bottom: 0;
    display: flex;
    justify-content: flex-end;

    :global(.close) {
      width: 32px;
      height: 32px;
      border: none;
    }
  }

  .body {
    padding-top: 0;
    padding-bottom: 1.5 * $space;

    .title {
      @include font-xxLarge;
      @include FontSemiBold;
      margin-bottom: $space;
    }
  }

  .footer {
    box-shadow: none;

    button + button {
      margin-left: $space;
    }
  }
}
