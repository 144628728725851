@import '_styles/core.scss';

.two-columns__desktop {
  display: flex;
  flex-direction: row-reverse;
}

.two-columns {
  display: block;
  padding: 0 (2 * $space);

  @include media('<tablet-lg') {
    padding: ($space * 4) $space;
  }

  @include media('<phone') {
    padding: ($space * 4) ($space * 2);
  }
}

.left-column {
  width: 100%;
}

.right-column {
  flex-grow: 0;
  flex-shrink: 0;

  @include media('<desktop') {
    width: 48.5 * $space;
  }

  @include media('<tablet-lg') {
    width: auto;
  }
}
