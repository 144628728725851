@import '../../../_styles/core.scss';

.award-track-card {
  display: grid;
  grid-template-rows: auto auto;
  width: 100%;
  box-shadow: $card-box-shadow;
  border-radius: 8px;

  &:hover {
    box-shadow: $card-box-shadow-hover;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: (3 * $space) (3 * $space) (3 * $space) (3.75 * $space);

    > div:nth-child(1) {
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        margin-right: 1.5 * $space;
      }

      .product-name {
        @include font-large;
      }

      label {
        @include font-small;
        @include FontSemiBold;
      }

      select,
      select:focus,
      select:focus-visible {
        border: none;
        outline: none;
        text-align: center;
        @include font-small;
        color: $gray80;
      }
    }

    > div:nth-child(2) {
      text-align: right;

      .status {
        @include font-large;
        @include FontSemiBold;

        &.green {
          color: $contrastgreen;
        }

        &.red {
          color: $contrastred;
        }

        &.gray {
          color: $gray70;
        }

        &.blue {
          color: $TRblue;
        }
      }

      .category-quantity {
        @include font-small;
        color: $gray80;
      }
    }
  }

  .body {
    height: 37.75 * $space;
    border-top: 1px solid $gray30;
    display: none;
    padding: ($space) 0 (0.25 * $space) (4 * $space);

    &.expanded {
      display: grid;
      grid-template-columns: (1.75 * $space) minmax(max-content, auto) 16% 26%;
      grid-template-rows: (2.5 * $space) auto auto auto;
      grid-column-gap: 2 * $space;
    }

    svg {
      margin-top: 0.75 * $space;
    }

    .progress-header,
    .top-rated-header {
      @include font-small;
      @include FontSemiBold;
      color: $gray30;
      text-transform: uppercase;
    }

    .criterion-block {
      max-width: 34.5 * $space;

      > div:nth-child(1) {
        @include font-large;
        @include FontBold;
      }

      > p {
        @include font-small;
        margin: 0;
        color: $gray60;
      }
    }

    .progress-block,
    .top-rated-block {
      > div:nth-child(1) {
        @include font-medium;
        @include FontBold;
      }

      > p {
        @include font-small;
        margin: 0;
        color: $gray60;
      }
    }
  }
}
