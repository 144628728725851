@import '_styles/core.scss';

.sr-only {
  @include sr-only;
}

.item-header {
  display: flex;
  flex-direction: column;

  > svg {
    flex: 0 0 auto;
    margin-right: $space * 2;
    position: relative;
    top: 4px;
  }
}

.heading {
  @include font-large;
  @include FontBold;
  margin-bottom: $space / 2;
  display: flex;
  align-items: center;
}

.description {
  @include font-small;
  color: $textlight;
  padding-left: 4.5 * $space;

  @include media('<=tablet') {
    margin-bottom: 1.5 * $space;
  }
}

.description.not-desktop {
  padding-left: 0;
}

.item {
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  gap: $space * 3;

  @include media('<=tablet') {
    gap: 0;
  }
}

.item.not-desktop {
  flex-direction: column;
  display: flex;
}

.header-text {
  padding-left: $space * 2;

  @include media('<=phone') {
    padding-left: $space;
  }
}

.values {
  @include spacingReset;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: $space * 3;
}

.value.not-desktop {
  display: flex;
}

.value {
  dd {
    @include spacingReset;
    color: $textlight;

    strong {
      color: $textblack;
      display: block;
    }

    @include media('<=phone') {
      margin-top: $space;
    }
  }
}

.not-desktop-title {
  @include font-small;
  @include FontSemiBold;
  text-transform: uppercase;
  color: $textlight;
}
