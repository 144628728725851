@import '_styles/core.scss';

.container {
  max-width: 20rem;
  text-align: center;

  .title,
  .description {
    font-size: var(--r-font-size-200);
    line-height: var(--r-font-line-height-200);
    margin: 0;
  }

  .title {
    font-weight: var(--r-font-weight-semibold);
    margin-top: 1rem;
  }

  .description {
    color: var(--r-color-gray-100);
  }
}
