.container {
  margin: 1rem;
  padding: 1.5rem;
  border: 1px solid var(--r-color-gray-40);
  border-radius: 0.5rem;
  color: var(--r-color-gray-100);
  max-width: 1500px;
}

.content-container {
  display: grid;
  grid-template-columns: minmax(200px, 500px) auto minmax(50px, 200px);
}

.header {
  h2,
  span {
    color: var(--r-color-gray-120);
  }
  h2 {
    margin-bottom: 0.5rem;
  }
}

.header.mobile {
  grid-column: 1 / span 2;
  margin-bottom: 1rem;
}

.icon {
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--r-color-gray-30);
  cursor: pointer;
}

.to,
.status,
.from {
  width: 150px;
  div {
    margin-bottom: 0.5rem;
  }
}

.status {
  width: 170px;
}

.from {
  a {
    padding: 0;
    margin: 0;
  }
}

.body {
  display: flex;
  justify-content: space-between;
}

.body.mobile {
  grid-column: 1 / span 2;
  justify-content: flex-start;
}

.status-text {
  padding: 0.125rem 0.75rem;
  background: var(--r-color-positive-fade);
  border-radius: 0.5rem;
  color: var(--r-color-positive);
  display: inline-block;

  div {
    margin-bottom: 0;
  }
  div::before {
    content: '\2022';
    color: var(--r-color-positive);
    font-weight: bold;
    display: inline-block;
    width: 1em;
  }
}

.status-error {
  color: var(--r-color-negative);
  background: var(--r-color-negative-fade);

  div::before {
    color: var(--r-color-negative);
  }
}

.error {
  padding: 0.625rem 1rem;
  margin-top: 1.5rem;
  border-radius: 0.375rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--r-color-negative);
  background: var(--r-color-negative-fade);

  a {
    display: flex;
    background: none;
    color: var(--r-color-negative);
    border: 0;
    padding: 0;
    width: 145px;

    &:hover,
    &:focus {
      text-decoration: underline;
      background: none;
      color: var(--r-color-negative);
      border: 0;
    }
  }
}

.alert-message {
  display: flex;
  align-items: center;
  width: 100%;
  padding-right: 1rem;
}

.alert-icon {
  width: 20px;
  margin-right: 1rem;
}

.actions {
  justify-self: end;
  align-self: center;
}

.action {
  button {
    border-radius: 0;
    text-align: left;
    padding: 0.25rem 1.5rem;
    display: block;
    width: 100%;
    height: 100%;
    color: var(--r-color-gray-120);
    font-weight: var(--r-font-weight-regular);

    &:hover {
      background: var(--r-color-blue-10);
      color: var(--r-color-gray-120);
    }
  }
}

.action.mobile {
  grid-row: 1 / span 2;
  grid-column-start: 3;
}
