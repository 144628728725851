.dialogOverlay {
  background-color: #000000;
  z-index: 3;
  opacity: 0.6;
  position: fixed;
  inset: 0;
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

.dialogContent {
  background-color: white;
  border-radius: 6px;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 472px;
  max-height: 85vh;
  padding: 1.5rem;
  z-index: 3;
  animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  overflow-y: auto;
}
.DialogContent:focus {
  outline: none;
}

.x {
  cursor: pointer;
  position: absolute;
  right: 1rem;
  top: 1rem;
  width: 1.5rem;
}

.icon {
  margin-bottom: 2rem;
}

.description {
  margin: 1rem 0;
}

.actions {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 3rem;
  column-gap: 1rem;
}

@keyframes overlayShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.6;
  }
}

@keyframes contentShow {
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}
