.wrapper {
  position: relative;

  label {
    word-break: break-all;
  }
}

.container {
  display: grid;
  grid-template-columns: 272px auto;
  max-width: 680px;
  border-radius: 8px;
  border: 1px solid var(--Gray-Gray-30, #e7e7e9);
  box-shadow: 0px 1px 32px 0px rgba(0, 0, 0, 0.1);
  transition: height 1s ease-out;
  color: var(--r-color-gray-90);
}

.container.mobile {
  grid-template-columns: 1fr;
  border: 0;
  border-radius: 0;
}

.disabled {
  width: 100%;
  position: absolute;
  height: 100%;
  z-index: 15;
  cursor: not-allowed;
}

.left-column {
  border-bottom-left-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
  padding: 1.5rem 2rem;
  background: linear-gradient(180deg, #141b2b 0%, #2b3758 100%);
  color: var(--r-color-white);
}

.left-column.mobile {
  border: 0;
  border-radius: 0;
}

.logo-container {
  padding: 2rem 0 3rem;
  display: grid;
  gap: 2.375rem;
  align-self: center;
  text-align: center;
  border-bottom: 1px solid var(--r-color-white);

  img {
    justify-self: center;
    background: var(--r-color-gray-40);
  }
}

.logo {
  width: 64px;
  border-radius: 0.5rem;
}

.reasons-title {
  text-align: center;
  padding: 1.5rem 0 1rem;
}

.reasons-title.mobile {
  text-align: left;
  font-weight: bold;
}

.reasons {
  font-size: var(--r-font-size-100);
  div {
    padding-bottom: 0.5rem;
  }
}

div.reason-label {
  padding: 0;
}

div.mobile-textbox {
  font-weight: bold;
  padding: 0;
}

.reasons.mobile {
  display: flex;
  justify-content: space-between;
}

.right-column {
  padding: 2.5rem 2rem 1.5rem;
  display: grid;
  gap: 1.25rem;
}

.two-fields-in-row {
  display: grid;
  grid-template-columns: minmax(120px, 1fr) minmax(120px, 1fr);
  gap: 0.5rem;
}

.submit {
  button {
    justify-content: center;
    width: 100%;
  }
}

.select {
  button {
    width: 100%;
  }
}
