@import '_styles/core.scss';

.top-level-item {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  border: none;
  width: 100%;
  transition: all 0.15s linear;
  cursor: pointer;
  border-radius: $border-radius-lg;

  &:hover {
    text-decoration: none;

    @include media('>tablet') {
      background-color: $bluefade;
    }
  }
}

.with-link-and-nested {
  a {
    flex: 1 0 auto;
    display: flex;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  button {
    border: none;
    padding: 0;
  }
}

.chevron {
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: $space / 2;
}

.icon-container {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 40px;
}

.item-text {
  flex: 1 0 auto;
  margin-right: $space;
  margin-left: $space;
  display: flex;
  justify-content: flex-start;
  gap: $space;
  align-items: center;
  height: 40px;
  color: $white;

  @include media('>tablet') {
    color: $textblackprimary;
  }
}

.link {
  text-decoration: none;
  display: block;
}

.collapsed-items {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
}

.open-collapsed-items {
  max-height: 1000px;
  transition: max-height 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
}

.nested-menu-item {
  padding-left: $space * 6;
  width: $space * 29.75;
  height: $space * 5;
  display: flex;
  align-items: center;
  border-radius: $border-radius-lg;
  color: rgba($white, 0.7);

  @include media('>tablet') {
    color: rgba($textblack, 0.7);

    &:hover {
      background: $bluefade;
    }
  }

  &:focus {
    background: none;
  }

  a {
    text-decoration: none;
    font-weight: normal;
    color: inherit;
  }
}

.is-active {
  @include media('>tablet') {
    color: $textblackprimary;
  }

  font-weight: bold;
}
