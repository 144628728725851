@import '_styles/core.scss';

.checkbox {
  display: flex;
  justify-content: flex-start;
  position: relative;
  min-height: $space * 3;

  &.small {
    min-height: $space * 2;
  }
}

.input {
  position: absolute;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 1;
  width: $space * 2.25;
  height: $space * 2.25;
  cursor: pointer;

  &.small {
    width: $space * 2;
    height: $space * 2;
  }

  &:checked + label {
    &::before {
      background-color: $TRblue;
      border-color: $TRblue;
    }

    &::after {
      background-size: $space $space;
      background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg version='1.1' viewBox='0 0 8 8' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg transform='translate%28-4 -4%29' fill='%23fff'%3E%3Cpolygon points='10.576 4 7.3018 9.3034 5.08 7.2604 4 8.5843 7.479 12 12 4.9927'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    }
  }

  &[disabled] {
    cursor: not-allowed;
  }

  &[disabled] + label {
    color: $gray70;
    cursor: not-allowed;

    &::before {
      border-color: $readonly;
      background-color: $gray10;
    }
  }

  &[disabled]:checked + label {
    &::before {
      background-color: $readonly;
      border-color: $readonly;
    }

    &::after {
      background-size: $space $space;
      background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg version='1.1' viewBox='0 0 8 8' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg transform='translate%28-4 -4%29' fill='%23fff'%3E%3Cpolygon points='10.576 4 7.3018 9.3034 5.08 7.2604 4 8.5843 7.479 12 12 4.9927'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    }
  }

  &:focus {
    & + label::before {
      box-shadow: 0px 0px 5px rgba(42, 173, 252, 0.5);
      border-color: $focus;
    }
  }

  &:checked:focus {
    & + label::before {
      border-color: $TRblue;
    }
  }

  &:focus-visible,
  &.focus-visible {
    & + label::before {
      outline: 5px auto Highlight;
      outline: 5px auto -webkit-focus-ring-color;
      outline-offset: 4px;
      border-radius: 0;
    }
  }
}

.label {
  @include spacingReset;
  @include font-medium;
  cursor: pointer;
  padding-right: $space * 0.5;
  padding-left: $space * 2.25;
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex: 1 0 auto;
  align-self: start;

  &::before,
  &::after {
    content: '';
    cursor: pointer;
    display: block;
    width: $space * 2.25;
    height: $space * 2.25;
    position: absolute;
    background-position: center;
    background-size: $space $space;
    top: 3px;
    left: 0;
    transition: background-color 0.1s linear, border-color 0.1s linear;
  }
  &::before {
    border: 1px solid $gray40;
    border-radius: 3px;
    background-color: $white;
  }

  &::after {
    background-position: center;
    background-repeat: no-repeat;
    border: 1px solid transparent;
    border-radius: 3px;
  }

  &.small {
    @include font-small;
    padding-left: $space * 2;

    &::before,
    &::after {
      display: block;
      background-size: $space $space;
      height: $space * 2;
      width: $space * 2;
      top: 1px;
    }
  }
}

.label-text {
  font-weight: normal;
  margin-left: $space * 1.25;
  display: block;
  flex: 1 1 auto;

  &.small {
    margin-top: 0;
  }
}

.label-sub-text {
  @include font-small;
  display: block;
  font-weight: normal;
  flex: 0 0 100%;
  margin-left: $space * 1.25;
}

.hidden-label {
  @include sr-only;
}
