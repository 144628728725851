@import '_styles/core.scss';

.awards {
  min-height: 100%;
  background-color: $backgroundgray;

  div.page-body {
    display: grid;

    @include media('<tablet-lg') {
      padding-top: 2 * $space;
      padding-bottom: 2 * $space;
      grid:
        'aside-cards'
        'award-cards';
      gap: 2 * $space;
    }

    @include media('>=tablet-lg') {
      grid: 'award-cards aside-cards' / 1fr 400px;
      gap: 3 * $space;
    }
  }
}

.header {
  box-shadow: 0 2px 14px rgba(0, 0, 0, 0.1);
  background: $white;
  position: sticky;
  top: 0;
  z-index: $zindex-pagetitle;

  @include media('<tablet-lg') {
    padding: (2 * $space) (2 * $space);
  }

  @include media('>=tablet-lg') {
    padding: (3 * $space) (4 * $space);
  }
}

.title {
  margin: 0;

  @include media('<tablet-lg') {
    @include h4;
  }

  @include media('>=tablet-lg') {
    @include h1;
  }
}

.subtitle {
  color: $textlight;

  @include media('<tablet-lg') {
    margin-top: 0.5 * $space;
    @include font-small;
  }

  @include media('>=tablet-lg') {
    margin-top: $space;
  }
}

.award-cards {
  grid-area: award-cards;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(auto, 280px));

  @include media('<tablet-lg') {
    gap: (2.5 * $space) (2 * $space);
    justify-content: center;
  }

  @include media('>=tablet-lg') {
    gap: (3.5 * $space) (3 * $space);
  }
}

.aside {
  grid-area: aside-cards;

  section.aside-card {
    padding: 0;
    border-radius: 4px;
    background-color: white;

    @include media('>=tablet-lg') {
      width: auto;
    }
  }

  .aside-card-header {
    border-bottom: none;
    padding: 0;
  }

  .aside-value-title {
    width: 100%;

    @include media('>=tablet-lg') {
      padding: (4 * $space) (3 * $space) 0;
    }
  }

  .aside-value-button {
    width: 100%;
    padding: 2 * $space;
    text-align: left;
  }

  .aside-value-children {
    padding: 0 (2 * $space) (2 * $space);

    @include media('<tablet-lg') {
      border-top: 1px solid $gray30;
      padding: 0 (2 * $space) (2 * $space);
    }

    @include media('>=tablet-lg') {
      padding: 0 (3 * $space) (3 * $space);
    }

    p {
      margin: (2 * $space) 0;
    }
  }
}

.awards-value-link {
  display: block;
  width: fit-content;
  @include FontSemiBold;

  &:hover,
  &:focus {
    text-decoration: none;
  }

  &:not(:last-child) {
    margin-bottom: $space;
  }
}
