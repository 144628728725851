@import '../../_styles/core.scss';

.popover {
  :global(.tr-popover-body) {
    background: $black;
    color: $white;
    border-radius: $popover-border-radius;
  }
  :global(.arrow) {
    &::after {
      border-bottom-color: $black !important;
    }
  }
}

.header-buttons {
  display: flex;
  gap: 1.5rem;
}

.api-key-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}

.button {
  @include font-medium;
  @include FontSemiBold;
  padding: (0.625 * $space) (3 * $space);

  &:hover {
    background-color: $darkTRblue;
    border-color: $xxdarkTRblue;
    text-decoration: none;
  }

  &:active {
    background-color: $xxdarkTRblue;
    border-color: $xxdarkTRblue;
  }
  &:focus,
  &:focus-visible {
    outline: none;
  }
}

.backdrop:global(.trModal-backdrop) {
  display: block;
}

.modal {
  .dialog:global(.modal-dialog) {
    max-width: 600px;
    min-height: calc(100vh - 3.5rem); // removing margins from modal styles
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .header {
    padding: 3 * $space 3 * $space 2 * $space;
    background-color: transparent;
    align-items: flex-start;

    .title {
      flex: 0 400px;
      color: $textblackprimary;
    }

    :global(.close) {
      padding: 0;
      width: 32px;
      height: 32px;
      border: none;
    }
  }

  .body {
    padding: 0 3 * $space 3 * $space 3 * $space;
    & p {
      margin-bottom: 3 * $space;
      padding-right: 6 * $space;
      color: $gray80;
    }

    .input {
      width: 100%;
      border: 1px solid $gray40;
      border-radius: 3px;

      &:focus,
      &:focus-visible {
        border: 1px solid $xlightTRblue;
        box-shadow: 0px 0px 5px rgba(42, 176, 252, 0.5);
        outline: none;
      }

      &::selection {
        background: $gray30;
      }
    }
  }

  .footer {
    padding: 0 3 * $space 3 * $space 3 * $space;
    box-shadow: none;
  }
}
