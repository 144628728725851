@import '_styles/core.scss';

.form-layout {
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  width: $space * 85;
  padding: 0;

  h2 {
    margin-top: $space * 2.75;
    margin-bottom: $space * 1.375;
  }
}

.modal-top {
  padding: ($space * 4) 0 ($space * 3);
  border-bottom: 1px solid $gray80;
}

.header {
  background: #1f283f;
  padding: 24px 32px;
}

.modal-body {
  padding: ($space * 5) ($space * 4) ($space * 3);

  .submit-button {
    width: 100%;
    background-color: $darkTRblue;
  }
}

.product-logo {
  max-width: $space * 8;
  max-height: $space * 8;
  margin: 0 auto 0;
  background-color: $white;
}

h2.vendor-text {
  @include font-xLarge;
  @include FontBold;
  color: $white;
  width: 100%;
  text-align: center;
  padding: ($space * 2) 0 $space 0;
}

h3.help-text {
  @include font-small;
  @include FontSemiBold;
  color: $white;
  text-align: center;
  margin: ($space * 3) 0 ($space * 2);
}

.options-container {
  margin-bottom: ($space * 5);

  .option-container {
    background: #1f283f;
    border-radius: $space * 0.5;
    padding: 0 $space;
    margin: $space 0;
    color: $white;
    height: $space * 4;
    display: flex;
    align-items: center;

    &:hover {
      background: #3a4a73;
    }

    label {
      color: white;
    }
  }
}

.two-field-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: $space;
}

.field-container {
  width: 100%;
  padding: ($space * 0.5) 0 ($space * 2);
}

.email-popover:global(.tr-popover) {
  z-index: $zindex-modal + 1;
}

.disclaimer {
  font-size: 13px;
  padding-top: $space;
  color: $gray70;
}

.legal-container {
  padding: $space * 2 0;

  input {
    margin-right: $space;
  }
}

.label-uppercase {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include font-small;
  @include FontSemiBold;
  text-transform: uppercase;
  color: $textlight;
  margin-bottom: $space;

  svg {
    width: 1em;
    height: 1em;
  }
}

.form-control {
  @include form-control;
}

select.form-control {
  opacity: 1;
  color: $gray60;
}

textarea.form-control {
  height: auto;
  padding-top: 5px;
  padding-bottom: 5px;

  &::placeholder {
    color: $gray60;
    opacity: 1;
  }
}

.lead-capture-notes {
  resize: none;
}

.notes-label {
  justify-content: start;
}

.notes-optional {
  @include FontItalic;
  @include font-medium;
  text-transform: lowercase;
}

@include media('<=tablet') {
  .form-layout {
    grid-template-columns: 1fr 2fr;
    width: 550px;
  }

  .header {
    padding: $space * 3;
  }

  .modal-body {
    padding-left: $space * 3;
    padding-right: $space * 3;
  }

  h2.vendor-text {
    @include font-medium;
    padding: 0;
  }

  .product-logo {
    max-width: $space * 8;
    max-height: $space * 8;
  }

  .options-container {
    margin-bottom: $space * 4;
  }
}
