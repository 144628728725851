@import '_styles/core.scss';

.container {
  padding: 0 ($space * 4) ($space * 3);
  border-radius: $border-radius-lg;
  background-color: $white;
}

.form-item {
  grid-column: 1 / -1;
}

.input-container {
  composes: form-item;

  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, max-content);
  gap: $space;

  .select {
    grid-column: 1 / -1;
  }
}

.label {
  @include spacingReset;
}

.form {
  display: grid;
  max-width: 600px;
  gap: $space * 3;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  margin-bottom: $space * 6;

  .time-period {
    grid-column: span 2;
  }

  .audience {
    grid-column: span 3;
  }
}

.help-circle {
  align-self: center;
}

.footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid $gray30;
  padding-top: $space * 3;

  > * + * {
    margin-left: $space * 2;
  }
}

.search-icon {
  display: block;
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
}

.fieldError {
  border: solid 1px $darkrose;
  border-radius: $space * 0.75;
}

.toast {
  :global(.close) {
    border: none;
  }

  :global(.sr-only) {
    @include sr-only;
  }
}
