@import '_styles/core.scss';

.body {
  > * + *:not(div) {
    margin-top: $space * 3;
  }

  > h3 {
    @include font-large;
    @include FontSemiBold;
  }

  > h3 + * {
    margin-top: $space;
  }

  > *:last-child {
    margin-bottom: 0;
  }
  padding: 0 $space * 3;
}
