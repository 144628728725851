@import '_styles/core.scss';

.label-wrapper {
  @include font-small;
  @include FontSemiBold;
  height: $space * 2.75;
  width: $space * 4.75;
  border-radius: $border-radius;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
}

.type-new {
  background-color: #fff0ac;
  color: #332508;
}

.type-beta {
  background-color: #9f54ff;
  color: #ffffff;
}
