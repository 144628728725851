@import '_styles/core.scss';

.button {
  border: none;
  padding: 0;
  margin: 0;
  background: transparent;
  height: 5.25 * $space;
  width: 5.25 * $space;
  display: flex;
  align-items: center;
  justify-content: center;
}

.desktop-right-block {
  margin-left: auto;
  padding-right: 1.25 * $space;
  display: flex;
  align-items: center;
}

.helpIcon {
  display: flex;
  column-gap: 10px;
  color: $white;
  margin-right: 4 * $space;
}
