.overlay {
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  inset: 0;
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 5;
}

.content {
  background: #ffffff;
  box-shadow: 0px 1px 32px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 600px;
  max-height: 85vh;
  padding: 1.5rem;
  animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 10;
}
.content:focus {
  outline: none;
}

.has-image {
  max-width: 400px;
  text-align: center;

  .title {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

.title {
  font-weight: var(--r-font-weight-semibold);
  font-size: var(--r-font-size-400);
  line-height: var(--r-font-line-height-400);
  color: var(--r-color-gray-120);
  margin-top: 0;
  margin-bottom: 1rem;
}

.description {
  color: var(--r-color-gray-100);
  margin: 0;
}

.close {
  all: unset;
  position: absolute;
  top: 1rem;
  right: 1rem;
  display: flex;
  height: 1.5rem;
  width: 1.5rem;
  cursor: pointer;
  color: var(--r-color-gray-120);
}

.actions {
  display: flex;
  justify-content: flex-end;
  gap: 0.75rem;
  margin-top: 2rem;
}

.has-image .actions {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 0.75rem;
  margin-top: 3rem;

  button {
    justify-content: center;
  }
}

.spinner {
  display: block;
  margin: 2rem auto 0.5rem;
  width: 2.75rem;
  height: 2.75rem;
  border: 3px solid transparent;
  border-radius: 50%;
  border-top-color: var(--r-color-blue-70);
  border-right-color: var(--r-color-blue-70);
  border-bottom-color: var(--r-color-blue-70);
  animation: spin 0.5s linear infinite;
}

.error {
  color: var(--r-color-negative);
}

@keyframes overlayShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes contentShow {
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
