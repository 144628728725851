@import '_styles/core.scss';

.backdrop:global(.trModal-backdrop) {
  display: block;
}

.modal {
  .dialog:global(.modal-dialog) {
    min-height: calc(100vh - 3.5rem); // removing margins from modal styles
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .header {
    background-color: transparent;
    display: flex;
    justify-content: flex-end;

    .title {
      color: $textblackprimary;
    }

    :global(.close) {
      width: 32px;
      height: 32px;
      border: none;
    }
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.body {
  max-height: 50vh;
  min-height: 400px;
  overflow: auto;
  padding-top: $space * 3;
  padding-left: $space * 4;
  padding-right: $space * 4;
}

.table {
  width: 100%;
  table-layout: fixed;

  th {
    @include font-small;
    @include FontSemiBold;
    text-align: left;
    color: $gray70;
    text-transform: uppercase;
    width: 50%;
    padding-right: $space * 4;
  }

  th,
  td {
    padding: $space / 2 0;
  }
}
