@import '_styles/core.scss';

.container {
  @include font-medium;
  overflow: hidden;
  height: 100%;
  text-align: center;
  padding-top: 12.5 * $space;
  background: radial-gradient(50% 50% at 50% 80%, #a3deff 20%, #f5fbff 100%);
}

.image {
  padding: 0% 20%;
  margin-top: 6.75 * $space;
  width: 100%;

  @include media('<phone') {
    padding: 0% (3.5 * $space);
  }
}

.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: $space * 3;
}

.cta {
  background: $lightTRblue;
  color: $white;
  width: 28.75 * $space;
  border-radius: 2.5 * $space;
  padding: $space 4 * $space;
}

.help {
  margin-top: $space;
  color: $lightTRblue;
}
