@import '_styles/core.scss';

.backdrop:global(.trModal-backdrop) {
  display: block;
}

.modal {
  .dialog:global(.modal-dialog) {
    max-width: 510px;
    min-height: calc(100vh - 3.5rem); // removing margins from modal styles
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .header {
    align-items: start;
    background-color: transparent;
    padding-left: 4 * $space;
    padding-bottom: 3 * $space;
    transition: box-shadow 0.15s;

    &.with-shadow {
      box-shadow: 0 1px 32px rgba(0, 0, 0, 0.15);
    }

    .title {
      flex-shrink: 1;

      h2 {
        @include font-large;
        @include FontSemiBold;
        margin-top: 1.5 * $space;
        color: $textblackprimary;
      }

      .subtitle {
        @include font-small;
        @include FontNormal;
        margin-top: $space;
        color: $gray80;
      }
    }

    :global(.close) {
      flex-shrink: 0;
      width: 32px;
      height: 32px;
      border: none;
    }
  }

  .body {
    max-height: 480px;
    overflow-y: auto;
    background-color: transparent;
    padding: 0 (4 * $space);
    padding-bottom: 4 * $space;

    .product-list {
      list-style: none;
      margin: 0;
      padding-left: 0;
    }

    .product-list-item {
      margin: $space 0;
      display: flex;
      align-items: center;

      img {
        flex-shrink: 0;
        border-radius: 0.5 * $space;
        margin-right: $space;
      }
    }
  }
}
