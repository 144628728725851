@import '_styles/core.scss';

.sr-only {
  @include sr-only;

  + .switch {
    margin-left: 0;
  }
}

.label {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  cursor: pointer;

  .switch {
    flex: 0 0 auto;
  }

  &.column {
    flex-direction: column;
    align-items: flex-start;
  }

  &.disabled {
    .switch {
      opacity: 0.5;

      .slider {
        cursor: not-allowed;
      }
    }
  }
}

.label-text {
  @include font-small;
  color: $bluechrome;
  font-weight: 600;

  .large & {
    @include font-medium;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: $space * 3.75;
  height: $space * 2;
  margin-bottom: 0;
  margin-left: $space * 1.25;

  .large & {
    width: $space * 5.75;
    height: $space * 3.125;
  }

  .column & {
    margin-top: $space * 2;
    margin-left: 0;
  }
}

.label-right {
  margin-left: 0;
  margin-right: $space * 1.25;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $gray30;
  transition: background-color 0.2s ease-in-out;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.12);
  border-radius: $space * 5;

  &:before {
    position: absolute;
    content: '';
    border-radius: 50%;
    height: $space * 1.5;
    width: $space * 1.5;
    left: 2px;
    bottom: 2px;
    background-color: $white;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12);
    transition: transform 0.2s ease-in-out;
  }

  .large &:before {
    height: 21px;
    width: 21px;
  }
}

.checkbox {
  @include sr-only;

  &:checked + .slider {
    background-color: $TRblue;
  }

  &:checked + .slider:before {
    transform: translateX(14px);

    .large & {
      transform: translateX(21px);
    }
  }

  &:focus-visible,
  &.focus-visible {
    & + .slider {
      outline: 5px auto Highlight;
      outline: 5px auto -webkit-focus-ring-color;
      outline-offset: 2px;
    }
  }
}
