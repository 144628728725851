@import '../../../_styles/core.scss';

.toast {
  position: fixed;
  top: $space * 10;
  right: $space * 6;
  z-index: $zindex-popover;
  min-width: $space * 35;
  background-color: $white;
  border-radius: $border-radius;
  box-shadow: $box-shadow-lg;
  opacity: 0;
  transform: translateX(25%);
  transition: all 0.25s ease-in-out;
  overflow: hidden;
  border: 1px solid transparentize($bluechrome, 0.8);
  pointer-events: none;
  max-width: 280px;

  &:after {
    z-index: 3;
  }

  &:before {
    z-index: 1;
    box-shadow: $box-shadow;
  }

  &:global(.show) {
    opacity: 1;
    transform: translateX(0);
    pointer-events: unset;
  }

  &--danger {
    border: 1px solid #f5c2c7;
  }

  &--success {
    border: 1px solid #badbcc;
  }
}

.toast-heading {
  padding: $space * 2 $space * 2;
  background: $bluefade;
  color: $bluechrome;
  @include FontSemiBold;
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
  @include font-medium;
  display: flex;
  align-items: center;
  position: relative;
  padding-right: $space * 7;
  min-height: 33px;

  > svg {
    margin-right: $space / 2;
  }

  > [data-dismiss='toast'] {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: $space * 5;
  }

  &--danger {
    background-color: #f8d7da;
    color: #842029;
    box-shadow: none;
  }

  &--success {
    background-color: #d1e7dd;
    color: #0f5132;
    box-shadow: none;
  }
}

.toast-body {
  box-shadow: inset 0 1px 0 0 transparentize($bluechrome, 0.8);
  position: relative;
  padding: $space * 2 $space * 2;
  z-index: 2;
  white-space: pre-wrap;
  // min-width: max-content;
}
