@import '_styles/core.scss';

.list {
  list-style: none;
  padding-left: $space;
  margin-top: $space * 1.5 0;
  margin-bottom: $space * 3;
}

.list-item {
  margin-top: $space;
}

.icon {
  color: $TRblue;
  margin-right: $space * 1.5;
}
