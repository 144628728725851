@import '_styles/core.scss';

.container {
  width: 7 * $space;
  position: relative;
}
.sidebar {
  position: absolute;
  width: 7 * $space;
  background: $white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 1;
  height: 100%;
  overflow: hidden;
  transition: width 0.25s cubic-bezier(0.77, 0.2, 0.05, 1);
  z-index: $zindex-sidebar;
  padding-left: $space * 2;
  padding-right: $space * 2;

  @include media('>tablet') {
    padding-left: 0;
    padding-right: 0;
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }
}

.sidebar.expanded {
  @include media('>tablet') {
    width: 31.5 * $space;
    transition-delay: 0.25s;
  }
}
