.gray120 {
  color: var(--r-color-gray-120);
}

.gray100 {
  color: var(--r-color-gray-100);
}

.cancel-button,
.delete-button {
  width: 100%;
  button {
    width: 100%;
    justify-content: center;
  }
}

.delete-icon {
  color: var(--r-color-negative);
  div {
    color: var(--r-color-negative);
    background: var(--r-color-negative-fade);
  }

  div div {
    color: var(--r-color-negative);
    background: #ffdeea;
  }
}

.delete-modal {
  align-items: center;
  text-align: center;
}
