@import '_styles/core.scss';

.section {
  max-width: 33.875rem;
}

.heading {
  font-size: var(--r-font-size-400);
  font-weight: var(--r-font-weight-semibold);
  line-height: var(--r-font-line-height-400);
  color: $gray120;
  margin: 0;
  padding: 0;
}

.date-time {
  margin: 0;
  color: var(--r-color-gray-100);
}

.dl {
  margin: 0;

  dt {
    font-weight: var(--r-font-weight-semibold);
    color: $gray120;
    margin-top: 1.5rem;
  }
  dd {
    color: var(--r-color-gray-100);
    padding: 0;
    margin: 0;
  }
}

.tags {
  list-style: none;
  padding: 0;
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.error {
  color: var(--r-color-negative);
}
