.icon {
  width: 84px;
  height: 84px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-inner {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg {
    stroke-width: 1px;
    width: 40px;
    height: 40px;
  }
}

.empty-lead {
  display: flex;
  flex-direction: column;

  svg {
    width: 25px;
    height: 25px;
    stroke-width: 1px;
  }
}
