// Use `font()` mixin to declare fonts directly if strictly necessary
// Fonts are globally attached to `.body` and this should not normally be required
// unless you should need to override fonts from a third party tool or some other
// similar use case.
@mixin font() {
  font-family: $font-family;
}

// Helper Mixin for setting font size and line height
@mixin buildFace($size, $lineHeight) {
  font-size: $size;
  line-height: $lineHeight;
}

// FONT WEIGHTS
// ---------------------------------------------------------- //

@mixin FontNormal {
  font-weight: 400;
  font-style: normal;
}

@mixin FontItalic {
  font-weight: 400;
  font-style: italic;
}

@mixin FontSemiBold {
  font-weight: 600;
  font-style: normal;
}

@mixin FontSemiBold-Italic {
  font-weight: 600;
  font-style: italic;
}

@mixin FontBold {
  font-weight: 700;
  font-style: normal;
}

// FONT SIZES
// ---------------------------------------------------------- //

@mixin font-super {
  @include buildFace(48px, (48/48));

  @include media('<=phone') {
    @include buildFace(33px, (40/33));
  }
}

@mixin font-xxxxLarge {
  @include buildFace(40px, (42/40));
}

@mixin font-xxxlLarge {
  @include buildFace(36px, (42/36));
}

@mixin font-xxxLarge {
  @include buildFace(33px, (40/33));
}

@mixin font-xxLarge {
  @include buildFace(28px, (36/28));
}

@mixin font-xLarge {
  @include buildFace(23px, (28/23));
}

@mixin font-large {
  @include buildFace(19px, (28/19));
}

@mixin font-medium {
  @include buildFace(16px, (24/16));
}

@mixin font-small {
  @include buildFace(14px, (18/14));
}

@mixin font-xSmall {
  @include buildFace(11px, (16/11));
}

@mixin font-xxSmall {
  @include buildFace(9px, (12/9));
}

@mixin tracking($tracking: 0.25rem) {
  letter-spacing: $tracking;
}

@mixin h1 {
  @include font-xxxLarge;
  @include FontBold;
}

@mixin h2 {
  @include font-xxLarge;
  @include FontBold;
}

@mixin h3 {
  @include font-xLarge;
  @include FontBold;
}

@mixin h4 {
  @include font-large;
  @include FontSemiBold;
}

@mixin h5 {
  @include FontSemiBold;
  @include font-medium;
}

@mixin h6 {
  @include font-small;
  @include FontSemiBold;
}

@mixin ul($type: null) {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;

  @if $type == 'unstyled' {
    list-style-type: none;

    li:before {
      content: '\200B';
    }
  }

  @if $type == 'bullet' {
    padding-left: 2rem;
    list-style-type: bullet;
  }

  li + li {
    margin-top: $space;
  }
}
