@import '_styles/core.scss';

.segments-container {
  margin-bottom: 3 * $space;
}

.segment-container {
  margin-bottom: $space;
}

.profile {
  margin-bottom: 2 * $space;

  .profile-name {
    @include h4;
    display: inline-block;
  }

  .profile-id {
    @include font-medium;
    display: inline-block;
  }
}

.textWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: $white;

  .banner-title {
    font-weight: 700;
  }

  .banner-subtitle {
    a {
      color: $white;
      text-decoration: underline;
      font-weight: normal;
    }
  }
}

.toast {
  :global(.close) {
    border: none;
  }

  :global(.sr-only) {
    @include sr-only;
  }
}
