@import '_styles/core.scss';

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $darkTRblue;
  height: $space * 8.75;
  padding: ($space * 1.5) ($space * 4) ($space * 1.5) ($space * 3);
}

.textWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: $white;

  :nth-child(1) {
    font-weight: 700;
  }

  :nth-child(2) {
    a {
      color: $white;
      text-decoration: underline;
      font-weight: normal;
    }
  }
}
