.content-container {
  max-width: 676px;
  padding: 0 2.5rem 2rem;

  a {
    padding: 0;
  }
}

.hidden-fields-container {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr 0.25fr;
  grid-gap: 1rem;

  input {
    padding: 0.675rem 0.75rem;
    width: 100%;
    border: 1px solid var(--r-color-gray-40);
    border-radius: 0.25rem;
  }
}

.mapping-container {
  max-width: 414px;
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 1.5fr 2.5fr;
  grid-gap: 1rem;

  input {
    padding: 0.675rem 0.75rem;
    width: 100%;
    border: 1px solid var(--r-color-gray-40);
    border-radius: 0.25rem;
  }
}

.field-container {
  height: 50px;
  display: flex;
  align-items: center;

  input {
    align-self: end;
    padding: 0.675rem 0.75rem;
    width: 100%;
    border: 1px solid var(--r-color-gray-40);
    border-radius: 0.25rem;
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  padding: 2rem;
  border-top: 1px solid var(--r-color-gray-30);
}

.right-container {
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
}

.right-container {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.block-container {
  margin-top: 3rem;

  h2 {
    color: var(--r-color-gray-120);
  }

  p {
    padding-top: 0.25rem;
    padding-bottom: 1rem;
    color: var(--r-color-gray-100);
  }

  input {
    padding: 0.675rem 0.75rem;
    width: 100%;
    border: 1px solid var(--r-color-gray-40);
    border-radius: 0.25rem;
  }
}

.input-with-delete {
  position: relative;
}

.delete-icon {
  align-self: center;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    padding: 0;
  }
}

.alert-container {
  max-width: 676px;
  margin: 2rem 2.5rem 0;
}

.alert-error {
  color: var(--r-color-negative);
}

.alert-success {
  color: var(--r-color-positive);
}

.modal {
  align-items: center;
  text-align: center;

  button {
    width: 100%;
    justify-content: center;
  }
}

.tooltip {
  all: unset;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  text-transform: uppercase;

  svg {
    color: var(--r-color-blue-60);
  }
}
