.IconButton {
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--r-color-gray-30);
}

.action {
  button {
    border-radius: 0;
    text-align: left;
    padding: 0.25rem 1.5rem;
    display: block;
    width: 100%;
    height: 100%;
    color: var(--r-color-gray-120);
    font-weight: var(--r-font-weight-regular);

    &:hover {
      background: var(--r-color-blue-10);
      color: var(--r-color-gray-120);
    }
  }
}
.container {
  display: flex;
  justify-content: space-between;
  border: 1px solid var(--r-color-gray-40);
  border-radius: 0.5rem;
  padding: 1.5rem;
  align-items: center;
  margin-bottom: 1.5rem;
}

.content-container {
  display: grid;
  grid-template-columns: 60% 20% 20%;
  grid-template-rows: auto 2rem;
  align-items: end;
  width: 100%;
  button {
    padding: 0;
  }
}

.actions {
  width: 32px;
}

.gray120 {
  color: var(--r-color-gray-120);
}

.gray100 {
  color: var(--r-color-gray-100);
}

.cancel-button,
.delete-button {
  width: 100%;
  button {
    width: 100%;
    justify-content: center;
  }
}

.cancel-button {
  button {
    color: var(--r-color-gray-120);
    border: 1px solid var(--r-color-gray-120);
  }
}

.dialog-title {
  color: var(--r-color-gray-120);
}

.item-list {
  margin-top: 1.5rem;
}

.product {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;

  img {
    width: 30px;
    max-height: 30px;
    margin-right: 0.5rem;
  }
}

.webhook {
  a {
    padding: 0;
  }
}

.applied {
  max-width: 472px;
}

.applied-buttons {
  padding: 0;
}

.webhook-hub-link {
  color: var(--r-color-blue-60);
  display: flex;
  justify-content: flex-end;

  a {
    padding: 0;
  }
}
