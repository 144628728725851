.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.75rem;
  background: var(--r-color-gray-20);
  color: var(--r-color-gray-120);
  border-radius: 0.5rem;

  button {
    padding: 0;
    color: var(--r-color-gray-120);
    width: 16px;
    min-width: 0;
    &:hover {
      color: var(--r-color-gray-140);
    }
  }
}
