@import '_styles/core.scss';

.tooltip {
  max-width: 286px;
  padding: $space;

  :global(.arrow) {
    transform: translateX(2.5 * $space) !important;
  }
}
