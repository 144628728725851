@import '_styles/core.scss';

.container {
  display: flex;
  align-items: center;
  padding-bottom: 2 * $space;
}

.logo {
  width: 40px;
}

.info {
  @include font-xSmall;
  display: flex;
  flex-direction: column;
  padding-left: 1.5 * $space;

  p {
    padding: 0;
    margin: 0;
    color: $gray80;
  }

  .name {
    @include font-small;
    @include FontBold;
    padding: 0;
    color: $textblackprimary;
  }
}
