@import '_styles/core.scss';

.container {
  width: 100%;
  height: 60px;
  background: $bluefade;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: $space / 2;
  padding: $space * 2 $space * 2 $space * 2 $space * 3;
  margin-bottom: $space * 5;
}

.message {
  display: flex;
  align-items: center;
  color: $gray70;
}

.refresh {
  border: 0;
  color: $darkTRblue;
  cursor: pointer;
  @include FontBold;
}

.close {
  border: 0;
}
