@import '_styles/core.scss';

.filter {
  flex-direction: row;
  display: flex;
  align-items: center;

  > :global(.dropdown) {
    min-width: 0;
  }

  @include media('<=tablet') {
    flex-direction: column;
  }

  :global {
    .dropdown-menu {
      transition: none;
    }
    .show {
      opacity: 1 !important;
      pointer-events: auto;
    }
  }

  .filter-label {
    @include FontSemiBold;

    @include media('<=tablet') {
      margin-right: $space;
    }
  }

  .filter-sublabel {
    @include FontSemiBold;
    @include font-small;
    color: $textblack;
    text-transform: uppercase;
    padding-left: 2 * $space;
  }
}

.filter-toggle {
  @include FontSemiBold;
  padding-left: $space * 2;
  padding-right: $space * 2;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  max-width: 100%;
  height: auto;
  flex: 1 1 auto;
  background: $white;
  border-radius: $border-radius;
  margin: 0 $space * 2 0 $space;
  > div {
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.filter-toggle-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdown-menu {
  padding: $space * 2 0;
  background-color: $white;
  box-shadow: $box-shadow;
  width: 100%;
  max-width: 62.5 * $space;
  border-radius: $border-radius;
  gap: $space * 2;
  z-index: $zindex-dropdown;

  &:global(.show) {
    display: grid !important;
  }
  @include media('<=tablet') {
    max-width: 90%;
  }
}

.dropdown-item-button {
  display: block;
  background: none;
  border: none;
  margin: 0;
  padding: $space/2 $space * 2.5;
  color: $textblack;
  width: 100%;
  text-align: left;

  &:hover,
  &:focus {
    background-color: $TRblue;
    color: $white;
    text-decoration: none;
  }
}

.divider {
  height: $space * 2;
}

.notDesktop {
  align-items: flex-start;

  .filter-label {
    padding-bottom: $space;
  }

  .filter-toggle {
    margin: 0 0 $space 0;
  }
}
