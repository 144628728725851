@import '_styles/core.scss';

.award-card {
  border-radius: 8px;
  background-color: white;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  @include transition(box-shadow);
  padding: (4 * $space) (3 * $space) (3.75 * $space);
  display: flex;
  flex-direction: column;
  align-items: center;

  &.active:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05), 0 4px 15px rgba(0, 0, 0, 0.15);
  }
}

.award-badge {
  height: 90px;
}

.award-card-title {
  margin: (2 * $space) 0 $space;
  @include font-xLarge;
}

.loader {
  height: 45px;
}

.award-card-values {
  display: flex;
}

.award-card-value-block {
  min-width: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.award-card-value {
  @include font-xLarge;
  @include FontBold;
}

.award-card-value-label {
  @include font-xSmall;
  color: $textlight;
}

.award-card-description {
  margin: $space 0 (2 * $space);
  text-align: center;
  color: $gray80;
}

.award-card-link {
  margin-top: auto;
}

.award-card-coming-soon {
  @include button-structure;

  margin-top: auto;
  cursor: default;
  border-radius: $space * 12.5;
  border-color: $gray30;
  color: $textlight;
  display: flex;
  align-items: center;
}
