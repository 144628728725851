@import '_styles/core.scss';

.loading {
  opacity: 0.25;
  transition: opacity 300ms 200ms linear;
}

.button-container {
  display: flex;

  > :not(:last-child) {
    margin-right: $space;
  }
}

.skeleton {
  width: 270px;
  height: 36px;
}

.toast {
  :global(.close) {
    border: none;
  }

  :global(.sr-only) {
    @include sr-only;
  }
}

.top-indent {
  margin-top: 2 * $space;
}
