@import '_styles/core.scss';

.tr-tooltip {
  position: absolute;
  top: 0;
  left: 0;
  z-index: $zindex-tooltip;
  display: block;
  max-width: $tooltip-max-width;
  word-wrap: break-word;
  color: $tooltip-body-color;
  background-color: black;
  background-clip: padding-box;
  border: $tooltip-border-width solid $tooltip-border-color;
  box-shadow: $box-shadow;
  border-radius: $tooltip-border-radius;
  padding: $space / 2 $space;
  @include FontNormal;
  @include font-small;

  .arrow {
    position: absolute;
    display: block;
    width: $tooltip-arrow-width;
    height: $tooltip-arrow-height;
    margin: 0 $border-radius;

    &::before,
    &::after {
      position: absolute;
      display: block;
      content: '';
      border-color: transparent;
      border-style: solid;
    }
  }
}

.bs-tooltip-top {
  margin-bottom: $tooltip-arrow-height + $tooltip-arrow-offset;

  > .arrow {
    bottom: calc((#{$tooltip-arrow-height} + #{$tooltip-border-width}) * -1);

    &::before,
    &::after {
      transform: translateX(($tooltip-arrow-height * -1) / 2);
    }

    &::before {
      bottom: 0;
      border-width: $tooltip-arrow-height ($tooltip-arrow-width / 2) 0;
      border-top-color: $tooltip-arrow-outer-color;
    }

    &::after {
      bottom: $tooltip-border-width;
      border-width: $tooltip-arrow-height ($tooltip-arrow-width / 2) 0;
      border-top-color: $tooltip-arrow-color;
    }
  }
}

.bs-tooltip-right {
  margin-left: $tooltip-arrow-height + $tooltip-arrow-offset;

  > .arrow {
    left: calc((#{$tooltip-arrow-height} + #{$tooltip-border-width}) * -1);
    width: $tooltip-arrow-height;
    height: $tooltip-arrow-width;
    margin: $border-radius 0; // make sure the arrow does not touch the tooltip's rounded corners

    &::before,
    &::after {
      transform: translateY(($tooltip-arrow-height * -1) / 2);
    }

    &::before {
      left: 0;
      border-width: ($tooltip-arrow-width / 2) $tooltip-arrow-height
        ($tooltip-arrow-width / 2) 0;
      border-right-color: $tooltip-arrow-outer-color;
    }

    &::after {
      left: $tooltip-border-width;
      border-width: ($tooltip-arrow-width / 2) $tooltip-arrow-height
        ($tooltip-arrow-width / 2) 0;
      border-right-color: $tooltip-arrow-color;
    }
  }
}

.bs-tooltip-bottom {
  margin-top: $tooltip-arrow-height + $tooltip-arrow-offset;

  > .arrow {
    top: calc((#{$tooltip-arrow-height} + #{$tooltip-border-width}) * -1);

    &::before,
    &::after {
      transform: translateX(($tooltip-arrow-height * -1) / 2);
    }

    &::before {
      top: 0;
      border-width: 0 ($tooltip-arrow-width / 2) $tooltip-arrow-height
        ($tooltip-arrow-width / 2);
      border-bottom-color: $tooltip-arrow-outer-color;
    }

    &::after {
      top: $tooltip-border-width;
      border-width: 0 ($tooltip-arrow-width / 2) $tooltip-arrow-height
        ($tooltip-arrow-width / 2);
      border-bottom-color: $tooltip-arrow-color;
    }
  }
}

.bs-tooltip-left {
  margin-right: $tooltip-arrow-height + $tooltip-arrow-offset;

  > .arrow {
    right: calc((#{$tooltip-arrow-height} + #{$tooltip-border-width}) * -1);
    width: $tooltip-arrow-height;
    height: $tooltip-arrow-width;
    margin: $border-radius 0; // make sure the arrow does not touch the tooltip's rounded corners

    &::before,
    &::after {
      transform: translateY(($tooltip-arrow-height * -1) / 2);
    }

    &::before {
      right: 0;
      border-width: ($tooltip-arrow-width / 2) 0 ($tooltip-arrow-width / 2)
        $tooltip-arrow-height;
      border-left-color: $tooltip-arrow-outer-color;
    }

    &::after {
      right: $tooltip-border-width;
      border-width: ($tooltip-arrow-width / 2) 0 ($tooltip-arrow-width / 2)
        $tooltip-arrow-height;
      border-left-color: $tooltip-arrow-color;
    }
  }
}

.bs-tooltip-auto {
  &[x-placement^='top'] {
    @extend .bs-tooltip-top;
  }

  &[x-placement^='right'] {
    @extend .bs-tooltip-right;
  }

  &[x-placement^='bottom'] {
    @extend .bs-tooltip-bottom;
  }

  &[x-placement^='left'] {
    @extend .bs-tooltip-left;
  }
}

.tr-tooltip {
  &.show {
    display: block !important;
  }

  &.fade {
    opacity: 1;
  }
}
