@import '_styles/core.scss';

.heading {
  margin: 0;
  font-weight: var(--r-font-weight-semibold);
  font-size: var(--r-font-size-400);
  line-height: var(--r-font-line-height-400);

  & + p,
  & ~ ul {
    margin-top: 1rem;
    color: var(--r-color-gray-100);
  }

  & ~ ul {
    padding-left: 1.5rem;
  }
}

.map-fields {
  margin-top: 3rem;
}

.list {
  font-size: var(--r-font-size-100);
  padding-left: 1.25rem;
  color: var(--r-color-gray-100);

  li + li {
    margin-top: 0.5rem;
  }
}

.field-section {
  margin-top: 2rem;
}

.subheading {
  margin: 0;
  font-weight: var(--r-font-weight-semibold);
  font-size: var(--r-font-size-300);
  line-height: var(--r-font-line-height-300);

  & + p {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    font-size: var(--r-font-size-100);
    line-height: var(--r-font-line-height-100);
    color: var(--r-color-gray-100);
  }
}

.headers-fields {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr)) 20px;
  gap: 2rem;
}

.label {
  font-weight: var(--r-font-weight-semibold);
  font-size: var(--r-font-size-100);
  text-transform: uppercase;
  color: var(--r-color-gray-110);
}

.fieldset {
  border: none;
  padding: 0;

  legend {
    @include sr-only;
  }
}

.fs-header {
  display: flex;
  gap: 2rem;
}

.fs-header-body {
  flex: 1 1 auto;
}

.fs-header-action {
  flex: 0 0 auto;
}

.radio-label {
  font-size: var(--r-font-size-200);
}

.form-table {
  width: 100%;
  margin-top: 3rem;

  caption {
    @include sr-only;
  }

  th,
  td {
    padding-bottom: 1.5rem;
  }

  thead {
    th {
      font-weight: var(--r-font-weight-semibold);
      font-size: var(--r-font-size-100);
      color: var(--r-color-gray-110);
      text-align: left;
    }
  }

  tbody {
    th {
      text-align: left;
      color: var(--r-color-gray-100);
      font-weight: var(--r-font-weight-normal);
    }

    tr:last-child {
      th,
      td {
        padding: 0;
      }
    }
  }
}

.tooltip {
  all: unset;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  text-transform: uppercase;

  svg {
    color: var(--r-color-blue-60);
  }
}

.add-header {
  padding-top: $space;
}

.delete-header {
  height: 36px;
  border-radius: 100%;
  border: 1px solid $gray30;
  justify-self: end;
  align-self: end;
  cursor: pointer;
}

.format {
  display: flex;
  flex-direction: row-reverse;
}

.error {
  margin-top: 0.5rem;
  color: var(--r-color-negative);
}
