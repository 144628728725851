.trigger {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border: 1px solid var(--r-color-gray-30);
  padding: 0.5rem 1rem;
  border-radius: 1.125rem;
  font-weight: var(--r-font-weight-regular);
  max-width: 20.375rem;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  b {
    font-weight: var(--r-font-weight-semibold);
  }

  &:hover,
  &:focus {
    border-color: var(--r-color-gray-50);
  }

  &[aria-expanded='true'] {
    border-color: var(--r-color-gray-50);

    svg {
      transform: rotate(180deg);
    }
  }
}

.popover-content {
  --local-row-value: var(--row-value, 5);
  border-radius: 0.5rem;
  padding: 2rem 2rem 0.75rem;
  width: 90vw;
  max-width: 35rem;
  background-color: var(--r-color-white);
  box-shadow: 0px 1px 32px rgba(0, 0, 0, 0.1);
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;

  &[data-state='open'][data-side='top'] {
    animation-name: slideDownAndFade;
  }
  &[data-state='open'][data-side='right'] {
    animation-name: slideLeftAndFade;
  }
  &[data-state='open'][data-side='bottom'] {
    animation-name: slideUpAndFade;
  }
  &[data-state='open'][data-side='left'] {
    animation-name: slideRightAndFade;
  }
}

.scroll-area {
  max-height: calc(
    (1.5rem * var(--local-row-value)) + (0.75rem * (var(--local-row-value) + 2)) +
      3.75rem + 0.944rem
  );
  overflow-x: auto;
  margin-top: 0.75rem;
  padding: 0.75rem 0 1.5rem;
}

.content {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: 1.5rem;
}

.fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

.legend {
  font-weight: var(--r-font-weight-semibold);
  font-size: var(--r-font-size-100);
  line-height: var(--r-font-line-height-100);
  color: var(--r-color-gray-70);
  text-transform: uppercase;
  display: flex;
}

.list {
  list-style: none;
  padding: 0;
  margin: 1rem 0 0;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: 0.75rem;
}

.search {
  position: relative;
  display: flex;
  align-items: center;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid var(--r-color-gray-40);

  &:focus-within {
    border-bottom: 1px solid var(--r-color-gray-60);
  }
}

.search-input {
  all: unset;
  flex: 1 0 auto;
  padding: 0.5rem 1rem;

  &::placeholder {
    color: var(--r-color-gray-60);
  }
}

.search-icon {
  position: absolute;
  right: 0;
  top: 0;
  padding: 0.5rem;
  color: var(--r-color-gray-100);
  pointer-events: none;
}

@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideRightAndFade {
  from {
    opacity: 0;
    transform: translateX(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideLeftAndFade {
  from {
    opacity: 0;
    transform: translateX(2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
