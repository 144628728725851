@import '_styles/core.scss';

.body {
  padding: 0 $space * 3;
}

.banner {
  background-color: $gray10;
  margin-top: $space * 2;
  display: flex;
  justify-content: center;
}

picture {
  width: 100%;
  height: auto;
}

.img {
  width: 100%;
  height: auto;
}
