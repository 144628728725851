@import '_styles/core.scss';

.radio {
  margin-bottom: 1.5 * $space;
  display: flex;
  justify-content: flex-start;

  & + & {
    margin-top: 0.75rem;
  }
}

.radio__input {
  position: absolute;
  left: -9999px;
  opacity: 0;

  &:checked + span::before {
    border-color: $TRblue;
    background-color: $TRblue;
    box-shadow: inset 0 0 0 3px $white;
  }
}

.radio__label {
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: normal;
  padding: 0;
}

.radio__label_text {
  color: $textblackprimary;
  display: inline-block;
  font-size: var(--r-font-size-200);
  line-height: var(--r-font-line-height-200);
  padding-left: 28px;
  position: relative;
  cursor: pointer;
  font-weight: normal;

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -9px;
    transition: background-color 0.2s linear, border-color 0.2s linear;
    width: 18px;
    height: 18px;
    border: 2px solid $gray40;
    border-radius: 50%;
    background-color: $white;
  }

  &:focus-within,
  &:focus,
  &:hover {
    &::before {
      border-color: $xdarkTRblue;
      background-color: $xdarkTRblue;
      box-shadow: inset 0 0 0 3px $white;
    }
  }
}

.radio__label_sub-text {
  display: block;
}
