@import '_styles/core.scss';

.container {
  background: white;
  padding: $space * 2;
  box-shadow: $box-shadow-sm, inset 0 2px 0 0 $xlightTRblue;

  @include media('>phone') {
    padding: $space * 4;
  }

  &:global(.no-highlight) {
    box-shadow: $box-shadow-sm;
  }

  &:global(.no-padding) {
    padding: 0;
  }

  &:global(.no-padding-bottom) {
    padding-bottom: 0;
  }

  &:global(.aside) {
    padding: $space * 2;

    @include media('>phone') {
      padding: $space * 3;
    }
  }
}
