.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: var(--r-color-white);
  display: flex;
  justify-content: space-between;
  padding: 2rem 2rem 2rem calc(2rem + 3.5rem);
  margin-top: 2rem;
  border-top: 1px solid var(--r-color-gray-30);
}

.test-actions,
.save-actions {
  display: flex;
  gap: 1rem;

  button {
    flex: 0 0 auto;
  }
}
