@import '_styles/core.scss';

.datecard {
  margin-bottom: 5 * $space;
  border: 1px solid $gray30;
  border-radius: $border-radius;
}

.footer {
  padding: $space * 1.5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo-time {
  display: flex;
  align-items: center;
}

.logos {
  flex: 0 0 auto;
  margin-right: $space;
}

.logo {
  aspect-ratio: 1 / 1;
  object-fit: contain;
  max-width: 50px;
  max-height: 50px;
  box-shadow: 0 0 0 2px white;
  border-radius: $border-radius * 1.5;
}

.logo + .logo {
  margin-left: -$space * 1.5;
}

.time {
  @include font-small;
  color: $textlight;
}

.actions {
  @include FontSemiBold;
  padding: $space * 3;
  display: flex;
  justify-content: flex-end;
}

.actions.mobile {
  justify-content: center;
}

.menu {
  @include spacingReset;
  list-style: none;
  min-width: 122px;

  li + li {
    margin-top: $space * 1.5;
  }
}

.menu-button {
  @include spacingReset;
  @include font-medium;
  border: none;
  background: transparent;
  display: block;
  width: 100%;
  text-align: left;
  cursor: pointer;

  &:focus,
  &:hover {
    color: $darkTRblue;
  }
}
