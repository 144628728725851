.trigger {
  border: 0px;
}

.DropdownMenuContent {
  margin-top: 0.25rem;
  min-width: 150px;
  background: var(--r-color-white);
  padding: 0.5rem 0;
  border: 1px solid var(--r-color-gray-50);
  border-radius: 0.5rem;
  box-shadow: 0px 2px 32px 0px rgba(0, 0, 0, 0.12),
    0px 12px 16px -12px rgba(0, 0, 0, 0.25),
    0px 1px 1.5px 0px rgba(0, 0, 0, 0.05);
}
