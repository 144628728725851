@import '../../_styles/core.scss';

.search {
  flex: 0 1 24.75rem;
  display: flex;
}

.search-input {
  @include form-control;
  flex: 1 1 auto;
  border-radius: 1.125rem;
  background-repeat: no-repeat;
  background-position: 1rem center;
  padding-left: 2.5rem;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%23c6c6c8' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='11' cy='11' r='8'%3E%3C/circle%3E%3Cline x1='21' y1='21' x2='16.65' y2='16.65'%3E%3C/line%3E%3C/svg%3E");
}

.toggle-group {
  display: flex;
  gap: 0.5rem;
}

.toggle-group-item {
  all: unset;
  min-height: 2.25rem;
  border-radius: 1.125rem;
  padding: 0 1rem;
  box-shadow: 0 0 0 1px var(--r-color-gray-40) inset;
  font-size: var(--r-font-size-200);
  line-height: var(--r-font-line-height-200);
  cursor: pointer;
  color: var(--r-color-gray-120);
  white-space: nowrap;

  &:focus,
  &:hover {
    box-shadow: 0 0 0 1px var(--r-color-gray-50) inset;
  }

  &[data-state='on'] {
    box-shadow: 0 0 0 2px var(--r-color-blue-70) inset;
    color: var(--r-color-blue-70);
  }

  &[data-disabled] {
    box-shadow: 0 0 0 1px var(--r-color-gray-40) inset;
    background-color: var(--r-color-gray-10);
    color: var(--r-color-gray-70);
    cursor: not-allowed;
  }
}
