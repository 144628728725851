@import '_styles/core.scss';

.container {
  padding: 0;
  position: relative;
}

.progress {
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  column-gap: $space * 3;
  margin-top: $space * 3;
  padding: 0 $space * 3;
}

.progress-header.notDesktop {
  display: none;
}

.progress-header {
  display: grid;
  gap: $space * 3;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-column: 2 / -1;

  > div {
    @include font-small;
    @include FontSemiBold;
    text-transform: uppercase;
    color: $textlight;
  }
}

.progress-body {
  grid-column: 1 / -1;
  margin-top: $space * 2;
  > * + * {
    padding-top: $space * 2;
    margin-top: $space * 2;
    border-top: 1px solid $gray30;
  }

  @include media('<=phone') {
    margin-top: 0;
  }
}

.filters {
  display: flex;
  justify-content: flex-start;
  padding: $space * 2;
}

.filters.notDesktop {
  flex-direction: column;
  align-items: flex-start;
  button {
    max-width: 31.75 * $space;
  }
}

.filter-container {
  display: flex;
  flex-direction: column;
  background: $gray10;
  margin-top: $space * 4;
}

.winner {
  background: linear-gradient(90deg, #116bf2 0%, #1b3ab5 100%);
  padding: $space * 2;
  @include font-large;
  @include FontSemiBold;
  color: $white;

  @include media('>phone') {
    padding-left: $space * 3;
    padding-right: $space * 3;
  }
}
