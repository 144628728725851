@import '_styles/core.scss';

.sr-only {
  @include sr-only;
}

.container {
  background-color: $white;
  border-radius: $border-radius-lg;
  padding: ($space * 2) ($space * 6) ($space * 2) ($space * 4);
  display: grid;
  grid-template-columns: 40px minmax(0, auto) 46px;
  grid-template-areas:
    'toggle header header'
    'toggle header header'
    'toggle pMetrics pMetrics'
    'toggle secondary secondary';

  @include media('>tablet') {
    grid-template-columns: 40px minmax(0, 1fr) minmax(0, 2fr) minmax(
        0,
        max-content
      );
    grid-template-rows: minmax(0, max-content) minmax(0, auto) minmax(0, auto);
    column-gap: $space * 2;
    grid-template-areas:
      'toggle header pMetrics pMetrics'
      'toggle header pMetrics pMetrics'
      'toggle secondary secondary secondary';
  }

  &.switch-enabled {
    grid-template-areas:
      'toggle header switch'
      'toggle header switch'
      'pMetrics pMetrics pMetrics'
      'secondary secondary secondary';

    @include media('>tablet') {
      grid-template-areas:
        'toggle header pMetrics switch'
        'toggle header pMetrics switch'
        'secondary secondary secondary secondary';
    }
  }
}

.toggle {
  grid-area: toggle;
  display: flex;
  margin-top: $space * 2;
}

.header {
  grid-area: header;
}

.heading {
  @include spacingReset;
  @include font-large;
  @include FontBold;
  color: $darkTRblue;
}

.description {
  color: $textlight;
}

.primary-metrics,
.secondary-content {
  @include spacingReset;
  border-top: 1px solid $gray30;
  padding-top: $space * 2;
  margin-top: $space * 2;
}

.secondary-content {
  grid-area: secondary;

  @include media('>tablet') {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.primary-metrics {
  align-self: center;
  grid-area: pMetrics;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: $space * 2;

  @include media('>tablet') {
    grid-template-columns: 1fr 2fr 1fr 1fr;
    gap: $space * 5;
    border: none;
    padding: 0;
    margin: 0;
    margin-left: auto;
    width: 100%;
  }

  dt {
    @include FontBold;
  }
}

.secondary-metrics {
  @include spacingReset;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: $space * 2;
  color: $textlight;

  @include media('>tablet') {
    grid-template-columns: repeat(4, minmax(0, max-content));
    padding-right: $space * 4;
    gap: $space * 4;
  }
  @include media('<=tablet') {
    > .description-block {
      grid-column: span 2;

      &:nth-child(1),
      &:nth-child(2) {
        grid-column: span 1;
      }
    }
  }

  dt {
    @include FontSemiBold;
  }
}

.switch {
  grid-area: switch;
  display: flex;
  align-items: center;

  @include media('>tablet') {
    justify-content: flex-end;
  }
}

.accounts {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: $space * 2;

  @include media('>tablet') {
    justify-content: flex-end;
    margin-top: 0;
  }

  a {
    @include FontBold;
    color: $darkTRblue;

    &:focus,
    &:hover {
      text-decoration: underline;
    }
  }

  .delete {
    @include media('>tablet') {
      margin-left: $space * 4;
    }
  }
}

.delete {
  margin-left: $space * 4;
}

.view-companies {
  color: $darkTRblue;
  text-decoration: none;
  border: 1px solid $gray30;
  border-radius: 50px;

  &:disabled {
    color: $gray70;
    border-color: $gray30;
    text-decoration: none;

    &:hover,
    &:focus {
      background: none;
      border-color: $gray30;
      color: $gray70;
    }
  }
}

.account-data {
  display: flex;
  flex-direction: column;
}

.toast {
  :global(.close) {
    border: none;
  }

  :global(.sr-only) {
    @include sr-only;
  }
}
