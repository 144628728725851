@import './config/variables.scss';

@import './tools/mixins/mixins.typography.scss';
@import './tools/mixins/mixins.spacing.scss';
@import './tools/mixins/mixins.anchor-target.scss';
@import './tools/mixins/mixins.buttons.scss';
@import './tools/mixins/mixins.table.scss';
@import './tools/mixins/mixins.forms.scss';
@import './tools/mixins/mixins.pillbadge.scss';
@import './tools/mixins/mixins.longform-typography.scss';
@import './tools/mixins/mixins.line-clamp.scss';
@import './tools/mixins/mixins.logo.scss';
@import './tools/mixins/mixins.sr-only.scss';
@import './tools/mixins/mixins.lists.scss';
@import './tools/mixins/mixins.swiper.scss';

// Cards
@import './tools/mixins/mixins.card-base.scss';
@import './tools/mixins/mixins.card-typography.scss';

@import './tools/transitions.scss';
@import './tools/animation.scss';

@mixin dropdownContainerStyle(
  $overflow: false,
  $max-height: 300px,
  $min-width: 188px,
  $max-width: 188px,
  $width: 100%
) {
  background-color: $white;
  border-radius: $border-radius;
  box-shadow: $box-shadow-lg;
  width: $width;
  min-width: $min-width;
  max-width: $max-width;
  z-index: $zindex-dropdown;
  border: 1px solid $gray20;

  @if $overflow {
    overflow: auto;
    max-height: $max-height;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

@mixin threeUpGrid() {
  display: flex;
  flex-wrap: wrap;
  margin-left: -$space * 2;
  margin-right: -$space * 2;

  > * {
    padding: 0 $space * 2;
    flex: 0 0 100%;

    &:nth-last-child(n + 2) {
      margin-bottom: $space * 4;
    }

    @include media('>tablet') {
      flex: 0 0 50%;

      &:nth-last-child(n + 3) {
        margin-bottom: $space * 4;
      }
    }

    @include media('>desktop') {
      flex: 0 0 33.333333%;

      &:nth-last-child(n + 4) {
        margin-bottom: $space * 4;
      }
    }
  }
}
