@import '_styles/core.scss';

.stars {
  display: flex;

  > * + * {
    margin-left: -0.3em;
  }
}

.star {
  height: 2.4em;
  width: 3.4em;
  flex: 0 0 auto;
  display: block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.full {
  background-image: url("data:image/svg+xml,%3Csvg width='34' height='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill-rule='nonzero' fill='none'%3E%3Cpath d='M8.45.81C8.63.32 9.1 0 9.62 0h23.55c.52 0 .89.52.7 1.01l-8.32 22.18c-.18.49-.65.81-1.17.81H.83c-.52 0-.89-.52-.7-1.01L8.45.81z' fill='%2300B2A3'/%3E%3Cpath d='M16.53 3.35c.16-.45.79-.45.94 0l1.89 5.41c.07.2.25.33.46.33l5.73.13a.5.5 0 0 1 .29.9l-4.56 3.47c-.17.13-.24.34-.18.54l1.65 5.49c.14.45-.37.82-.76.55l-4.71-3.26a.496.496 0 0 0-.57 0L12 20.18c-.39.27-.9-.1-.76-.55l1.65-5.49a.49.49 0 0 0-.18-.54l-4.56-3.47a.5.5 0 0 1 .29-.9l5.73-.13c.21 0 .39-.14.46-.33l1.9-5.42z' fill='%23FFF'/%3E%3C/g%3E%3C/svg%3E%0A");
}

.half {
  background-image: url("data:image/svg+xml,%3Csvg width='34' height='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill-rule='nonzero' fill='none'%3E%3Cpath d='M8.45.81C8.63.32 9.1 0 9.62 0h23.55c.52 0 .89.52.7 1.01l-8.32 22.18c-.18.49-.65.81-1.17.81H.83c-.52 0-.89-.52-.7-1.01L8.45.81z' fill='%23DDDDE2'/%3E%3Cpath d='M8.45.81C8.63.32 9.1 0 9.62 0H22.5l-9 24H.83c-.52 0-.89-.52-.7-1.01L8.45.81z' fill='%2300B2A3'/%3E%3Cpath d='M16.53 3.35c.16-.45.79-.45.94 0l1.89 5.41c.07.2.25.33.46.33l5.73.13a.5.5 0 0 1 .29.9l-4.56 3.47c-.17.13-.24.34-.18.54l1.65 5.49c.14.45-.37.82-.76.55l-4.71-3.26a.496.496 0 0 0-.57 0L12 20.18c-.39.27-.9-.1-.76-.55l1.65-5.49a.49.49 0 0 0-.18-.54l-4.56-3.47a.5.5 0 0 1 .29-.9l5.73-.13c.21 0 .39-.14.46-.33l1.9-5.42z' fill='%23FFF'/%3E%3C/g%3E%3C/svg%3E%0A");
}

.empty {
  background-image: url("data:image/svg+xml,%3Csvg width='34' height='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill-rule='nonzero' fill='none'%3E%3Cpath d='M8.45.81C8.63.32 9.1 0 9.62 0h23.55c.52 0 .89.52.7 1.01l-8.32 22.18c-.18.49-.65.81-1.17.81H.83c-.52 0-.89-.52-.7-1.01L8.45.81z' fill='%23DDDDE2'/%3E%3Cpath d='M16.53 3.35c.16-.45.79-.45.94 0l1.89 5.41c.07.2.25.33.46.33l5.73.13a.5.5 0 0 1 .29.9l-4.56 3.47c-.17.13-.24.34-.18.54l1.65 5.49c.14.45-.37.82-.76.55l-4.71-3.26a.496.496 0 0 0-.57 0L12 20.18c-.39.27-.9-.1-.76-.55l1.65-5.49a.49.49 0 0 0-.18-.54l-4.56-3.47a.5.5 0 0 1 .29-.9l5.73-.13c.21 0 .39-.14.46-.33l1.9-5.42z' fill='%23FFF'/%3E%3C/g%3E%3C/svg%3E%0A");
}

.extraSmall {
  font-size: 0.75 * $space;
}

.halfSmall {
  font-size: 1.25 * $space;
}

.small {
  font-size: 1.5 * $space;
}

.normal {
  @include font-small;
}

.star-rating-title {
  @include font-medium;
  margin-right: 17px;
  margin-left: 9px;
}
