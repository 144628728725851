@import '_styles/core.scss';

.sr-only {
  @include sr-only;
}

.help_popover {
  max-width: 200px;
  width: auto;
}

.trigger-button {
  @include spacingReset;
  border: 0;
  background: transparent;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
