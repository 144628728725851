@import '_styles/core.scss';
.app {
  min-height: 100vh;
}

.container {
  overflow: hidden;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: max-content auto;
  position: relative;
}

.error {
  background-color: var(--r-color-gray-30);
}

html body[data-scroll-locked] {
  margin-right: 0 !important;
}
