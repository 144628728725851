// Box Shadow
$box-shadow-sm: 0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.25);
$box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15), 0px 2px 4px rgba(0, 0, 0, 0.12);
$box-shadow-md: 0px 3px 6px rgba(0, 0, 0, 0.1),
  0px 10px 20px rgba(0, 0, 0, 0.15);
$box-shadow-lg: 0px 5px 10px rgba(0, 0, 0, 0.05),
  0px 15px 25px rgba(0, 0, 0, 0.15);

$card-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
$card-box-shadow-hover: 0px 2px 8px rgba(0, 0, 0, 0.1);
