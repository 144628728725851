$form-control-focus-color: #2ab0fc;

@mixin form-control {
  @include font-medium;
  display: block;
  height: 36px;
  width: 100%;
  box-shadow: none;
  background-color: $white;
  border: 1px solid $gray40;
  color: $textblack;
  padding: 0 $space;
  border-radius: 3px;
  font-weight: 500;
  background-image: none;

  &::placeholder {
    color: $gray60;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 5px 0 fade($form-control-focus-color, 50%);
    border-color: $form-control-focus-color;
  }
}

// styles for close "X" button for modals, popovers, etc.
@mixin dismissButton {
  @include transition;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  text-indent: -999px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-x'%3E%3Cline x1='18' y1='6' x2='6' y2='18'%3E%3C/line%3E%3Cline x1='6' y1='6' x2='18' y2='18'%3E%3C/line%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center center;
  margin-right: -$space;
}
