.container {
  display: grid;
  gap: 1rem;
  color: var(--r-color-gray-100);

  textarea {
    min-height: 11rem;
  }
}

.name-container {
  position: relative;
}

.tooltip {
  position: absolute;
  top: 0.5rem;
  right: 0;
  color: var(--r-color-gray-80);
}

.gray70 {
  color: var(--r-color-gray-70);
}

.gray100 {
  color: var(--r-color-gray-100);
}

.error {
  color: var(--r-color-negative);
}

.example {
  p {
    margin: 0;
    padding: 0;
  }
}
