@import '_styles/core.scss';

.dropdown {
  position: relative;
}

.dropdown-menu {
  @include dropdownContainerStyle;
  display: none;
  position: absolute;
  background-color: $white;
  box-shadow: $box-shadow;
  border-radius: $border-radius;
  border: 1px solid $gray40;
  padding: ($space/2) 0;
  margin-left: 0;
  margin-top: 3px;
  z-index: $zindex-dropdown;

  &.show {
    display: block;
  }
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: $space/2 $space * 2;
  background-color: $white;
  color: $trdarkgray;
  text-align: left;
  background-color: white;
  background-image: none;
  border: none;
  display: flex;
  align-items: center;

  svg {
    height: $space * 2.5;
    width: $space * 2.5;
    margin-right: $space;
    flex-shrink: 0;
    flex-grow: 0;
  }

  &:focus,
  &:hover {
    background-color: $xlightTRblue;
    color: $white;
    text-decoration: none;
  }
}
