@import '_styles/core.scss';

.button {
  @include button-structure;
  display: flex;
  align-items: center;
  justify-content: center;
  // basic styles
  color: $gray80;
  background-color: $gray20;

  &:hover {
    border-color: $gray60;
    text-decoration: none;
  }

  &:focus {
    outline: none;
  }

  &:focus-visible {
    border-color: $gray60;
    outline: 1px dotted rgba(0, 0, 0, 0.5);
  }

  &:active {
    background-color: $gray30;
    transition: background-color 0s;
  }

  &[disabled],
  &[disabled]:focus,
  &[disabled]:hover {
    background-color: $gray70;
    border-color: $gray70;
    color: $white;
    cursor: not-allowed;
  }

  svg {
    display: block;
    flex: 0 0 auto;
    height: $space * 2.25;
    width: $space * 2.25;
  }

  span + svg,
  svg + span {
    margin-left: $space * 1.25;
  }

  &.min-width {
    min-width: 120px;
  }
}

.button-large {
  @include button-large();
}

.button-icon {
  padding: 0 $space * 2;
  display: flex;
  align-items: center;
}

.button-icon-text {
  display: flex;
  align-items: center;
  padding: 0 $space * 3 + 1 0 $space * 3 - 1;

  span {
    position: relative;
    top: -1px;
  }

  &[aria-expanded] {
    svg {
      transition: transform 0.15s linear;
    }
  }

  &[aria-expanded='true'] {
    svg {
      transform: rotate(-180deg);
    }
  }
}

.button-primary {
  color: $white;
  background-color: $darkTRblue;
  border-color: $darkTRblue;

  &:hover {
    color: $white;
    border-color: $xdarkTRblue;
  }

  &:focus,
  &:focus-visible {
    color: $white;
    border-color: $xlightTRblue;
  }

  &:active {
    color: $white;
    border-color: $xdarkTRblue;
    background-color: $xdarkTRblue;
  }

  &.round {
    border-radius: $space * 12.5;
  }
}

.button-primary-pill {
  @extend .button-primary;
  border-radius: 50px;
}

.button-secondary {
  color: $darkTRblue;
  background-color: $white;
  border-color: $darkTRblue;

  &:hover {
    border-color: $xdarkTRblue;
  }

  &:focus,
  &:focus-visible {
    border-color: $xlightTRblue;
    background-color: $white;
    color: $darkTRblue;
  }

  &:active {
    border-color: $darkTRblue;
    background-color: $bluefade;
  }

  &[disabled],
  &[disabled]:focus,
  &[disabled]:hover {
    background-color: $gray10;
    border-color: $gray50;
    color: $gray50;
    cursor: not-allowed;
  }
}

.button-secondary-pill {
  @extend .button-secondary;
  border-radius: 50px;
}

.button-positive {
  color: $white;
  background-color: $contrastgreen;
  border-color: $contrastgreen;

  &:hover {
    border-color: $contrastgreen;
  }

  &:focus,
  &:focus-visible {
    border-color: $contrastdarkred;
    background-color: $contrastgreen;
    color: $white;
  }

  &:active {
    border-color: $contrastgreen;
    background-color: $contrastgreen;
  }
}

.button-positive-pill {
  @extend .button-positive;
  border-radius: 50px;
}

.button-negative {
  color: $white;
  background-color: $contrastgreen;
  border-color: $contrastred;

  &:hover {
    border-color: $contrastdarkred;
  }

  &:focus,
  &:focus-visible {
    border-color: $contrastdarkred;
    background-color: $contrastred;
    color: $white;
  }

  &:active {
    border-color: $contrastdarkred;
    background-color: $contrastdarkred;
  }
}

.button-negative-pill {
  @extend .button-negative;
  border-radius: 50px;
}

.button-light {
  color: $bluechrome;
  background-color: $white;
  border-color: $gray30;

  &:hover {
    background-color: $gray10;
    border-color: $gray70;
  }

  &:focus,
  &:focus-visible {
    border-color: $gray30;
  }

  &:active {
    border-color: $gray30;
    background-color: $gray10;
  }
}

.button-editorial {
  @include button-editorial();
}

.button-editorial-large {
  @include button-large();
  @include button-editorial();
}

.button-negative {
  color: $white;
  background-color: $contrastred;
  border-color: $contrastred;

  &:hover {
    color: $white;
    border-color: $contrastdarkred;
  }

  &:focus,
  &:focus-visible {
    color: $white;
    border-color: $contrastdarkred;
  }

  &:active {
    color: $white;
    border-color: $contrastdarkred;
    background-color: $contrastdarkred;
  }
}

.button-warning {
  color: $white;
  background-color: $warning;
  border-color: $warning;

  &:hover {
    border-color: $warningchrome;
  }

  &:active {
    background-color: #e67629;
    border-color: #e67629;
  }
}
.button-outline {
  color: $textblackprimary;
  background-color: $white;
  border-color: $gray30;

  &:hover {
    border-color: $gray40;
  }

  &:active {
    background-color: $gray40;
    border-color: $gray40;
  }
}

.button-link {
  border: none;
  background: none;
  color: $bluechrome;
  text-decoration: underline;

  &:hover {
    color: $bluechrome;
    text-decoration: none;
  }

  &:focus {
    outline: none;
  }

  &:focus,
  &:focus-visible {
    outline: $pastelblue auto 5px;
  }

  &:active {
    color: $bluechrome;
    background: none;
  }

  &:disabled {
    background: none;
    cursor: not-allowed;
    color: rgba($bluechrome, 0.5);
    text-decoration: underline;
  }
}

.button-plain {
  background: transparent;
  border: none;
  padding: 0;
  height: auto;
  color: $bluechrome;

  &:active {
    background: transparent;
  }
}

.button-dark {
  color: $white;
  background-color: $bluechrome;
  border-color: $bluechrome;

  &:hover {
    background-color: darken($bluechrome, 5%);
    border-color: darken($bluechrome, 5%);
  }

  &:focus,
  &:focus-visible {
    outline: $pastelblue auto 5px;
  }

  &:active {
    background-color: darken($bluechrome, 5%);
    border-color: darken($bluechrome, 5%);
  }
}

.button-primary-new {
  color: $white;
  background-color: $darkTRblue;
  border-color: $darkTRblue;

  &:hover {
    background-color: darken($darkTRblue, 5%);
    border-color: darken($darkTRblue, 5%);
  }

  &:focus,
  &:focus-visible {
    outline: $pastelblue auto 5px;
  }

  &:active {
    background-color: darken($darkTRblue, 5%);
    border-color: darken($darkTRblue, 5%);
  }

  &.round {
    border-radius: $space * 12.5;
  }
}

.button-outline-new {
  color: $darkTRblue;
  background-color: $white;
  border-color: $gray30;

  &:hover {
    border-color: $gray40;
  }

  &:active {
    background-color: $gray40;
    border-color: $gray40;
  }

  &.round {
    border-radius: $space * 12.5;
  }
}
