@import '_styles/core.scss';

.searchContainer {
  display: flex;
  align-items: center;
}

.search {
  @include font-medium;
  width: 100%;
  border: none;
  padding: ($space * 2) ($space * 2) ($space * 2) 0;

  &.white {
    color: $white;
  }

  &.black {
    color: $textblackprimary;
  }

  &:active,
  &:focus {
    outline: 0;
  }
}

.mobile {
  color: $white;
}

.separator {
  border-left: 1px solid $gray70;
  margin: 0 (2.5 * $space);
}

.label {
  display: flex;

  button {
    padding: 0;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:focus {
    color: $textblackprimary;
  }
}
