// TOOLTIP

$tooltip-body-color: $white;

$tooltip-bg: $black !default;
$tooltip-max-width: 276px !default;
$tooltip-border-color: $gray40;
$tooltip-border-radius: $border-radius !default;
$tooltip-border-width: 1px;
$tooltip-box-shadow: $box-shadow !default;
$tooltip-arrow-offset: 4px;

$tooltip-arrow-width: $space * 1.75;
$tooltip-arrow-height: $space * 1;
$tooltip-arrow-color: $tooltip-bg !default;

$tooltip-arrow-outer-color: $tooltip-border-color !default;
