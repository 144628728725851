@import '_styles/core.scss';

.metricContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-right: $space * 6;

  @include media('<desktop') {
    padding-right: $space * 5;
  }
}

.numberContainer {
  @include font-xxxlLarge;

  @include media('<desktop') {
    @include font-large;
  }

  span {
    @include font-small;
    margin-left: $space / 4;
    vertical-align: super;

    svg {
      margin-right: $space / 4;
    }
    @include media('<desktop') {
      @include font-xSmall;
    }
  }
}

.number {
  display: inline-block;
}

.title {
  @include font-small;

  @include media('<desktop') {
    @include font-xSmall;
  }
}

.positive {
  color: #28a228;
}

.negative {
  color: #cf000f;
}

.neutral,
.no-data {
  color: $gray70;
}

.arrow {
  position: relative;
  top: -2px;
}
.arrowNegative {
  transform: rotate(180deg);
}
