@import '_styles/core.scss';

.button {
  @include spacingReset;
  background: transparent;
  border: none;
  height: $space * 5.25;
  width: $space * 5.25;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.label {
  @include sr-only;
}

.action-menu {
  position: relative;
  width: max-content;
}

.menu {
  position: absolute;
  top: 100%;
  right: 0;
  width: max-content;
  max-width: 250px;
  box-shadow: $box-shadow;
  background-color: $white;
  padding: $space * 3;
}
