@import '_styles/core.scss';

.wrapper {
  margin-top: 1.5em;
  display: flex;
  justify-content: center;
}

.cta {
  @include font-medium;
  display: inline-flex;
  padding: $space * 0.625 $space * 3;
}
