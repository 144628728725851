.content {
  align-items: center;
  text-align: center;

  button {
    width: 100%;
    justify-content: center;
  }
}

.input-container {
  width: 100%;
  margin-top: 2rem;

  input {
    border: 1px solid var(--r-color-gray-40);
    border-radius: 0.18rem;
    width: 100%;
    height: 2.5rem;
    padding: 0.3rem 0.5rem;
  }
}

.name-length {
  display: flex;
  justify-content: flex-end;
}

.input.error {
  border-color: var(--r-color-negative);
}
.error {
  color: var(--r-color-negative);
}
