// COLORS

$mine-shaft: #2c2c2c;

// vendor portal
$header-bg: $mine-shaft;

// base colors
$black: #151521;
$white: #fff;
$trdarkgray: #424951;

// shades of gray
$gray10: #f5f5f7;
$gray20: #ebebf0;
$gray30: #dddde2;
$gray40: #c3c3ca;
$gray50: #a1a1a9;
$gray60: #808088;
$gray70: #65656f;
$gray80: #4a4a54;
$gray90: #2f2f39;
$gray120: #191919;

// brand colors
$xxlightTRblue: #8cd7ff;
$xlightTRblue: #21b1ff;
$xlightTRblueSaturated: #2ab0fc;
$lightTRblue: #0593ff;
$TRblue: #116bf2;
$darkTRblue: #1e50e5;
$xdarkTRblue: #1b3ab5;
$xxdarkTRblue: #0a4091;
$xxlightgreen: #dafef8;
$xlightgreen: #0be5c1;
$lightgreen: #04dbb4;
$TRgreen: #008a03;
$green: #00b2a3;
$mediumgreen: #007f74;
$darkgreen: #0093a1;
$xdarkgreen: #007097;
$xlightrose: #ffb780;
$lightrose: #ff8e7a;
$rose: #ff6966;
$darkrose: #ed3755;
$xdarkrose: #b12a50;
$xxlightplum: #ff99c2;
$xlightplum: #ff6e9c;
$lightplum: #e34d7f;
$plum: #d1287a;
$darkplum: #991a6a;
$xdarkplum: #5d145f;

// pastels
$pastelblue: #75a6f5;
$pastelgreen: #66d0c7;
$pastelorange: $xlightrose;
$pastelplum: #e281af;
$pastelrose: $lightrose;
$peachpuff: #ffd3b2;

$steelblue: #7f8fa4;

// text colors
$textblack: #31313c;
$textlight: $gray70;
$textblackprimary: #191919;

$backgroundgray: #f6f6f6;
$readonly: #d0d6d9;
$focus: #2aadfc;

$greenfade: #ebf7f8;
$bluefade: #ecf4ff;
$bluechrome: #002269;

$positivefade: #dbfdf8;
$positive: $green;
$positivechrome: #095d5e;

$negativefade: #feeff4;
$negative: $darkrose;
$negativechrome: #892038;

$warningfade: #ffeee8;
$warning: #ff934a;
$warningchrome: #945528;

$contrastgreen: #227d22;

$mediumred: #ffdeea;
$contrastred: #b40a0a;
$contrastdarkred: #7a0606;
