@import '_styles/core.scss';

.banner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner.mobile {
  display: block;
}

.body {
  padding: $space * 3 $space * 3 0;
}

.img {
  width: 100%;
  height: auto;
}
