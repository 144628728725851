@import '_styles/core.scss';

.popover {
  &:global(.tr-popover) {
    background-color: transparent;
    border: none;
    box-shadow: none;
    border-radius: $popover-border-radius * 2;
  }

  :global(.tr-popover-body) {
    padding: $space ($space * 1.5);
    background: $textblackprimary;
    color: $white;
    border-radius: $popover-border-radius * 2;
  }

  &:global(.bs-popover-bottom) {
    margin-top: 0;
    :global(.arrow) {
      top: ($popover-arrow-height * 1.75 / 2) * -1;
    }
  }

  :global(.arrow) {
    margin: 0;
    width: $popover-arrow-width;
    height: $popover-arrow-height * 1.75;
    z-index: -1;

    &::before {
      display: none;
    }
    &::after {
      top: 0;
      width: 100%;
      height: 100%;
      border: none;
      background-color: $textblackprimary;
      transform: rotate(45deg);
    }
  }
}

.metricsContainer,
.metricsGroup,
.metricsBlock {
  display: flex;
}

.metricsContainer.mobile {
  display: flex;
  padding: $space * 2;
  flex-direction: column-reverse;

  @include media('<phone') {
    padding: (1.5 * $space) (2 * $space);
  }
}

.metricsBlock.mobile {
  display: flex;
  flex-direction: column;
}

.metricsGroup.mobile {
  display: flex;
  justify-content: space-between;
  div {
    width: 100%;
  }
}

.tool-tip-text {
  @include buildFace(13px, (22/13));
  @include FontSemiBold;
}
