@import '_styles/core.scss';

.error-container {
  background-color: $negativefade;
  border-radius: $border-radius;
  padding: $space * 2;
  display: flex;

  svg {
    flex: 0 0 $space * 3;
  }

  svg + .text-content {
    margin-left: $space * 2;
  }

  & + * {
    margin-top: $space * 4;
  }
}

.message {
  color: $negative;
  background-position: left center;
  background-repeat: no-repeat;
  margin-bottom: $space;
}

.error-list {
  @include font-small;
  @include spacingReset;
  margin-left: 1.5em;
}

.hidden {
  display: none !important;
}
