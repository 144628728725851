@import '../../_styles/core.scss';

.card {
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 19.625rem;
  background-color: $white;
  padding: 2rem 1.5rem;
  border-radius: 0.5rem;
  border: 1px solid var(--r-color-gray-20);
  box-shadow: 0px 0.125rem 0.5rem rgba(0, 0, 0, 0.05);
  position: relative;
  transition: box-shadow 0.15s linear;

  &:not(.coming-soon):hover,
  &:not(.coming-soon):focus {
    text-decoration: none;
    box-shadow: 0px 0.25rem 0.5rem rgba(3, 9, 26, 0.02),
      0px 0.875rem 2rem -0.25rem rgb(3 9 26 / 0.1);
  }

  .card-content {
    flex: 0 0 auto;
  }

  .card-footer {
    flex: 0 0 auto;
  }

  .header {
    max-width: 100%;
    height: 2.5rem;
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-between;

    .coming-soon-label {
      background: #8a3ffc;
      color: var(--r-color-white);
      padding: 0.25rem 0.5rem;
      border-radius: 0.25rem;
      height: 1.9rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img {
      object-fit: contain;
    }
  }

  .title {
    margin-bottom: 1rem;
    color: var(--r-color-gray-120);
  }

  .text {
    margin-bottom: 1.25rem;
    color: var(--r-color-gray-100);
  }

  .button {
    position: absolute;
    bottom: 2rem;
  }
}

.learn-more-disabled {
  @include button-structure;
  margin-right: auto;
  cursor: default;
  border-radius: 6.25rem;
  border-color: var(--r-color-gray-30);
  color: var(--r-color-gray-80);
  display: flex;
  align-items: center;
  width: fit-content;
}

.card-learn-more {
  @include button-structure;
  border-radius: 6.25rem;
  border-color: $darkTRblue;
  display: flex;
  align-items: center;
  width: fit-content;
}

.integrations-card:hover .card-learn-more {
  border-color: $xdarkTRblue;
}

.integrations-card:active .card-learn-more {
  background-color: $bluefade;
}
