@import '_styles/core.scss';

.container {
  background: $header-bg;
}

.logo {
  display: block;
  height: 32px;
  width: 186px;
}

.header {
  min-height: 8 * $space;
  max-height: 8 * $space;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: $space * 2;
  padding-right: $space * 2;

  @include media('>tablet') {
    padding-right: $space * 3;
  }
}

.mobile-menu {
  position: fixed;
  top: 96px;
  left: 0;
  bottom: 0;
  width: 100%;
  background: $header-bg;
  z-index: 4;
  transform: translateY(-12px);
  opacity: 0;
  pointer-events: none;
  transition: all 0.2s ease-in-out;
  overflow: auto;
}

.mobile-menu-expanded {
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto;
}

.legacy {
  background: $bluefade;
  text-align: center;
  padding: $space / 2 $space * 2;

  button {
    @include font-small;
    @include FontSemiBold;
    text-decoration: underline;
    border: none;
    color: $xdarkTRblue;
    cursor: pointer;

    &:hover,
    &:focus {
      color: $xdarkTRblue;
    }
  }
}

.helpIcon {
  display: flex;
  column-gap: 10px;
  color: $white;
}
