.empty-state-container {
  height: 400px;
  margin: 2rem;
  padding: 1rem;
  border: 1px solid var(--r-color-gray-40);
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  max-width: 450px;
  height: 270px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    color: var(--r-color-gray-120);
    text-align: center;
    margin: 1rem 0 0.5rem;
  }

  p {
    color: var(--r-color-gray-100);
    text-align: center;
    margin-bottom: 2rem;
  }
}

.button-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 1rem;

  button {
    width: 100%;
    justify-content: center;
  }
}
