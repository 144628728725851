@import '_styles/core.scss';

.container {
  background-color: $white;
  border-radius: $border-radius-lg;
  padding: $space * 4;
  display: flex;
  justify-content: space-between;
}

.heading {
  @include sr-only;
}

.body {
  a {
    color: $textblack;
    text-decoration: underline;

    &:focus,
    &:hover {
      text-decoration: none;
    }
  }
}

.segment-footer {
  align-self: center;

  a {
    @include FontBold;
    color: $darkTRblue;
  }
}
