@import '_styles/core.scss';

.body {
  height: 100%;

  &.menu-open {
    overflow: hidden;
  }
}

@include media('>tablet') {
  .body {
    overflow: auto;
  }
}
