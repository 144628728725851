.table {
  width: 100%;

  .thead {
    background: var(--r-color-gray-10);
    text-align: left;

    th {
      color: var(--r-color-gray-90);
      font-weight: var(--r-font-weight-semibold);
      font-size: var(--r-font-size-100);
      line-height: var(--r-font-line-height-100);
      border-bottom: 1px solid var(--r-color-gray-20);
      padding: 0.75rem 1.5rem;

      svg {
        margin-left: 0.25rem;
      }

      &:focus,
      &:hover {
        background-color: var(--r-color-gray-20);
      }

      &:active {
        background-color: var(--r-color-gray-40);
      }
    }
  }

  tbody {
    th,
    td {
      padding: 1rem 1.5rem;
      border-bottom: 1px solid var(--r-color-gray-20);
    }

    tr:last-child {
      td {
        border-bottom: none;
      }
    }
  }
}
