@import '_styles/core.scss';

.header {
  padding-right: 4 * $space;
  height: 15.5 * $space;
  box-shadow: $page-header-box-shadow;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .award-name-wrapper {
    @include font-xxLarge;
    @include FontBold;
    display: flex;
    align-items: center;
    height: 100%;

    img {
      margin-right: 2 * $space;
    }

    span {
      @include FontNormal;
    }

    .back-wrapper {
      padding: 0 (2 * $space);
      cursor: pointer;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .separator {
      width: 1px;
      background-color: $gray30;
      height: $space * 8;
      margin: 0 (4 * $space) 0 0;
    }
  }

  .header-metrics {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @include media('<=tablet') {
    height: 10.25 * $space;

    .award-name-wrapper {
      @include font-large;

      .back-wrapper {
        padding: 0 $space;
      }
    }
  }

  @include media('<=phone') {
    height: 8 * $space;
  }
}
