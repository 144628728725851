@import '_styles/core.scss';

.header {
  background-color: white;
  border-bottom: 1px solid var(--r-color-gray-30);

  .header-width-wrapper {
    @include media('>=tablet') {
      max-width: 1246px;
      padding: 2rem;
      display: flex;
      justify-content: space-between;
      gap: 1.5rem;
      align-items: center;
    }
  }
}

.title-block {
  display: flex;
  padding: 1rem 1.5rem;
  gap: 1.5rem;

  @include media('>=tablet') {
    padding: 0;
  }
}

.action-block {
  border-top: 1px solid var(--r-color-gray-30);
  padding: 1rem 1.5rem;

  @include media('>=tablet') {
    border: none;
    padding: 0;
    white-space: nowrap;
  }
}

.has-shadow {
  border-bottom: none;
  box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.1);
}

.back-link {
  padding-right: 1rem;
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  border-right: 1px solid var(--r-color-gray-30);

  svg {
    stroke: var(--r-color-blue-70);
  }
}

.title-container {
  flex: 1;
  align-items: flex-start;
  display: flex;
  align-items: center;
  gap: 1rem;

  img {
    flex: 0 0 auto;
  }
}

.title-content-block {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.title {
  margin: 0;
}

.subtitle {
  color: var(--r-color-gray-90);
}
