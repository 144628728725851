.body {
  padding: 2rem 2.5rem 8.3125rem;
  max-width: 47.25rem;
}

.tabs-list {
  box-shadow: 0 -1px 0 0 var(--r-color-gray-30) inset;
  display: flex;
  gap: 1.5rem;
}

.tab-trigger {
  padding: 0.5rem 0.5rem calc(0.5rem + 0.1875rem);
  border: none;
  box-shadow: 0 -3px 0 0 transparent inset;
  color: var(--r-color-gray-100);
  font-weight: var(--r-font-weight-semibold);
  transition: box-shadow 0.1s linear;

  &:focus,
  &:hover {
    box-shadow: 0 -3px 0 0 var(--r-color-gray-90) inset;
  }

  &[data-state='active'] {
    box-shadow: 0 -3px 0 0 var(--r-color-blue-60) inset;
  }
}

.tab-content {
  padding: 3rem 0 0;

  & > section + section {
    margin-top: 3rem;
  }
}

.alerts:not(:empty) {
  margin-top: 3rem;
}

.alert-text {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 1rem;

  a {
    font-weight: var(--r-font-weight-semibold);
    text-decoration: none;
    color: var(--color, var(--r-color-blue-70));
  }
}
