@import '_styles/core.scss';

.page {
  min-height: 100%;
  background-color: $backgroundgray;

  &.loading {
    opacity: 0.25;
    transition: opacity 300ms 200ms linear;
  }
}

.button-container {
  display: flex;
}

.dropdown {
  margin-left: 2 * $space;

  .dropdown-menu {
    display: block;
    width: auto;
    max-width: none;
    border: none;
    box-shadow: $box-shadow-md;
    padding: (2.5 * $space) 0;

    > * {
      padding-left: 3 * $space;
      padding-right: 3 * $space;
    }

    .learn-more {
      &:focus,
      &:hover {
        background-color: $darkTRblue;
      }
    }

    .remove-linkedin {
      font-weight: 600;
      color: #b40a0a;

      &:focus,
      &:hover {
        background-color: #b40a0a;
        color: white;
      }
    }
  }
}

.dropdown-toggle {
  width: 36px;
  height: 36px;
  padding: 0;
  border-color: $gray30;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    stroke: $textblack;
  }
}

.body-container {
  padding: (2 * $space) (3 * $space);

  dd {
    margin-left: 0;
  }
}
