@import '_styles/core.scss';

.container {
  width: 100%;
}

.loading {
  height: 450px;
  width: 100%;
  margin-bottom: $space * 5;
}

.error {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: $gray10;
  padding: 90px 0;
  p {
    padding-bottom: $space * 3;
    margin-top: 0;
    text-align: center;
  }
}

.error-button {
  width: 133px;
  height: 36px;
  cursor: pointer;
  border: 1px solid $lightTRblue;
  border-radius: 18px;
  color: $lightTRblue;
  background: $white;
}
