@import '_styles/core.scss';

.container {
  overflow: hidden;
  height: 100%;
  text-align: center;
  @include font-medium;
}

.background {
  background: radial-gradient(
      82.55% 82.55% at 50% 50%,
      #ffffff 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    linear-gradient(to top, #a3deff -60%, #f5fbff 50%);
  height: 100%;
  width: 100%;
}

.heading {
  padding-top: 186px;
  padding-bottom: $space;
  @include h3;
}
