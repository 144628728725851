@import '_styles/core.scss';

@keyframes loading {
  from {
    transform: translateX(-100%) skew(315deg);
  }
  to {
    transform: translateX(100%) skew(315deg);
  }
}

.shimmer {
  position: relative;
  overflow: hidden;
  border-radius: $border-radius-lg;
  background: transparentize($gray10, 0.5);
  height: 100%;
  width: 100%;
}

.shimmer:after {
  content: '';
  background: linear-gradient(
    90deg,
    transparent 25%,
    rgba(255, 255, 255, 0.1) 25%,
    rgba(255, 255, 255, 0.5) 50%,
    rgba(255, 255, 255, 0.5) 75%,
    transparent 75%
  );
  display: block;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  transform: translateX(0%) skew(315deg);
  animation: loading 1s infinite ease-in;
}
