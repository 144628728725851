@import '_styles/core.scss';

.menu {
  margin: $space * 2;

  @include media('>tablet') {
    margin: $space * 2 $space;
  }
}

.separator {
  border-top: 1px solid $gray80;
  margin: 0 (3 * $space);
}

.user-block {
  margin-top: $space;
  display: flex;
  justify-content: space-between;
  margin: $space (3 * $space) 0;
}

.user-info {
  display: flex;
  align-items: center;
}

.full-name {
  @include font-medium;
  margin-left: $space;
  color: $white;
}

.logout {
  padding: 0;
  border: none;
  background: none;
  color: $white;
}
