@import '_styles/core.scss';

.metrics {
  min-height: 100%;
  min-width: 100%;

  div.page-body {
    @include media('<tablet-lg') {
      padding-top: 2 * $space;
      padding-bottom: 2 * $space;
      grid:
        'aside-cards'
        'metrix-iframe';
      gap: 2 * $space;
    }

    @include media('>=tablet-lg') {
      grid: 'metrix-iframe aside-cards' / 1fr 400px;
      gap: 3 * $space;
    }
  }
}

.metrix-iframe {
  min-height: 90%;
  height: 86vh;
  @include media('<tablet-lg') {
    gap: (2.5 * $space) (2 * $space);
    justify-content: center;
  }

  @include media('>=tablet-lg') {
    gap: (3.5 * $space) (3 * $space);
  }
}
