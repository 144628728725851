@import '_styles/core.scss';

.searchResultContainer {
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
}

.radio {
  @include font-small;
  input {
    &:checked + span::before {
      border-color: $TRblue;
      background-color: $white;
      box-shadow: inset 0 0 0 3px $TRblue;
    }
  }
  span {
    @include font-medium;
    &:focus-within,
    &:focus,
    &:hover {
      &::before {
        border-color: $TRblue;
        background-color: $white;
        box-shadow: inset 0 0 0 3px $TRblue;
      }
    }
  }
}

.title {
  @include font-small;
  @include FontSemiBold;
  color: $gray70;
  text-transform: uppercase;
  padding-bottom: $space * 2;
}

.mobile {
  span {
    color: $white;
  }
}

.highlightText {
  @include FontBold;
}
