@import '_styles/core.scss';

.input-container {
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, max-content);
  gap: $space;
}

.label {
  @include spacingReset;
  display: block;
}

.input {
  @include form-control;
  grid-column: 1 / -1;
}

.help-circle {
  align-self: center;
}

.help {
  @include spacingReset;
  @include font-small;
  color: $textblack;
}

.optional {
  color: $textlight;
}

.hide-label {
  @include sr-only;
}
