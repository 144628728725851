@import '../../_styles/core.scss';

.heading {
  margin: 0;
}

.popover {
  :global(.tr-popover-body) {
    background: $black;
    color: $white;
    border-radius: $popover-border-radius;
  }
  :global(.arrow) {
    &::after {
      border-bottom-color: $black !important;
    }
  }
}

.page-header {
  box-shadow: $page-header-box-shadow;

  @include media('>tablet') {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.75rem 2rem;
  }
}

.title-block,
.action-block {
  padding: 1rem 1.5rem;

  @include media('>tablet') {
    padding: 0;
  }
}

.action-block {
  border-top: 1px solid var(--r-color-gray-30);

  @include media('>tablet') {
    border: none;
  }
}

.page-body {
  max-width: 74.8125rem;
  margin: 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, auto));
  gap: 1.5rem;
}

.card-wrapper {
  display: flex;
}
