@import '_styles/core.scss';

.tr-score {
  display: flex;
  align-items: center;
  color: $bluechrome;
  border: none;
  background: none;
  padding: 0;

  &[data-size='large'] {
    @include font-large;

    .flag {
      height: 3 * $space;
      width: 4.5 * $space;
      margin-left: $space;
    }
  }

  &[data-size='small'] {
    @include font-small;

    .flag {
      height: 1.75 * $space;
      width: 2.75 * $space;
      margin-left: 0.5 * $space;
    }

    @include media('>phone') {
      @include font-medium;
    }
  }
}

.rating-count {
  color: $textlight;
  margin-left: $space * 2;
}

.label,
.score {
  @include FontSemiBold;

  @include media('<desktop') {
    @include font-xSmall;
  }
}

.label + .score {
  @include FontNormal;
  color: $TRblue;
}

.flag {
  background-image: url("data:image/svg+xml,%3Csvg width='48' height='32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.35 0c-.21 0-.39.13-.47.32L9.75 6h5.94c.2 0 .39.08.53.22l3.56 3.56c.14.14.22.33.22.53v16.4c0 .22-.27.33-.43.18l-3.35-3.35A.79.79 0 0 1 16 23V10H8.25l-8 21.32c-.12.33.12.68.47.68h34.93c.21 0 .39-.13.47-.32l.97-2.58L26 18l2.43-2.43c.05-.05.11-.07.18-.07H32V10h-6v13c0 .2-.08.39-.22.53l-3.35 3.35c-.16.16-.43.05-.43-.17v-16.4c0-.2.08-.39.22-.53l3.56-3.56a.75.75 0 0 1 .53-.22h5.38c.2 0 .39.08.53.22l3.56 3.56c.14.14.22.33.22.53v4.88c0 .2-.08.39-.22.53l-3.53 3.53 6.2 6.2L47.74.67a.49.49 0 0 0-.46-.67H12.35z' fill='%23116BF2' fill-rule='nonzero'/%3E%3C/svg%3E%0A");
  background-size: cover;
  margin-right: $space / 4;
}

.hide {
  display: none;
}
