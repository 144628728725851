@import '_styles/core.scss';

.productButton {
  display: flex;
  align-items: center;
  border: none;
  cursor: pointer;
  padding: 0;
}

.productName {
  @include h1;
  max-width: 52.75 * $space;
  padding-right: $space;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;

  @include media('<tablet-lg') {
    @include font-xLarge;
  }

  @include media('<phone') {
    @include font-large;
  }
}

.productSwitcherContainer {
  width: 100 * $space;
  min-height: 48.5 * $space;
  background: $white;
  position: relative;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: $border-radius;
  padding: 4 * $space 4 * $space 0;
  z-index: $zindex-modal;
  top: 28.75 * $space;
  left: 7.25 * $space;

  @include media('<desktop') {
    top: 21.5 * $space;
  }
}

.notDesktop {
  height: calc(100vh - 240px);
  width: 100%;
  left: 0;
}

.notDesktop .searchResults {
  height: 91%;
  color: $textblackprimary;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: $zindex-modal-backdrop;
}

.searchResults {
  position: relative;
  color: $textblackprimary;
  overflow: hidden;
  height: 37.75 * $space;

  &::before {
    content: '';
    position: absolute;
    height: 30px;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background: linear-gradient(
      180deg,
      #ffffff 19.27%,
      rgba(255, 255, 255, 0) 100%
    );
    pointer-events: none;
  }

  &::after {
    content: '';
    position: absolute;
    height: 30px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background: linear-gradient(
      0deg,
      #ffffff 19.27%,
      rgba(255, 255, 255, 0) 100%
    );
    pointer-events: none;
  }

  & .searchResultsContainer {
    height: 100%;
    overflow-y: scroll;
    padding-top: 3 * $space;
    padding-bottom: 2 * $space;
  }

  li label {
    &:hover {
      background: $darkTRblue;
      border-radius: $border-radius;
      width: 100%;
      span {
        @include FontBold;
        color: $white;
      }
    }
  }
}

.arrowBlack {
  fill: $textblackprimary;
  transform: rotate(180deg);
}

.arrowBlackReversed {
  fill: $textblackprimary;
  transform: rotate(0deg);
}

.empty {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.options {
  margin-bottom: 16px;
  span {
    @include buildFace(16px, (20/16));
    color: $textblackprimary;
  }

  label {
    padding-top: 0;
    padding-bottom: 0;
    color: $textblackprimary;
  }
}

.search-wrapper {
  padding: 0 (1.5 * $space);
}

.separator {
  border-top: 1px solid $gray40;
}
