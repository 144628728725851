@import '_styles/core.scss';

.container {
  display: flex;
  flex-direction: column;
}

.empty {
  width: 100%;
  height: 244px;
  background: $gray10;
  color: $gray90;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
