@import './core.scss';

// Set global font styles
:where(body) {
  font-family: $font-family;
  @include font-medium;
  color: $textblackprimary;
}

// Reset heading margin and padding
:where(h1, h2, h3, h4, h5, h6) {
  margin: 0;
}

// Reset paragraph margins to 0
:where(p) {
  margin: 0;
}

// Reset sibling paragraphs to have margins between them
:where(p + p) {
  margin-top: 1.5em;
}

// Set global basic link styles
:where(a[href]) {
  color: $darkTRblue;
  text-decoration: none;

  &:focus,
  &:hover {
    text-decoration: underline;
  }
}

// Show focus outlines only when navigating with keyboard.
:where(.js-focus-visible :focus:not(.focus-visible)) {
  outline: none;
}

:where(#root) {
  background: $white;
  border-radius: $space;
}

/* total width */
body::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

/* background of the scrollbar except button or resizer */
body::-webkit-scrollbar-track {
  background-color: #fff;
}

/* scrollbar itself */
body::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid #fff;
}

/* set button(top and bottom of the scrollbar) */
body::-webkit-scrollbar-button {
  display: none;
}
