@import '_styles/core.scss';

.productHeaderContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 16.75 * $space;
  padding: 0 (4.25 * $space);
  background: $white;
  box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.1);

  @include media('<desktop') {
    height: 9.25 * $space;
    padding: 0 (3 * $space);
  }

  @include media('<phone') {
    height: 9.25 * $space;
    padding: 0 (2.5 * $space);
  }
}

.product {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.loading {
  width: 31.25 * $space;
  height: 8.5 * $space;

  @include media('<desktop') {
    height: 7 * $space;
  }

  @include media('<tablet-lg') {
    height: 5.5 * $space;
  }
}

.search {
  input {
    &:active,
    &:focus {
      box-shadow: 0 1px 0 0 $TRblue;
    }
  }
}

.productTitle {
  display: flex;
  align-items: center;
}

.metricsContainer {
  display: flex;
}

.trustScore {
  display: flex;
  align-items: center;
}

.metrics {
  display: flex;
  align-items: flex-start;
}

.tablet {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  padding: (1.5 * $space) (3 * $space);
}
