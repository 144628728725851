@import '_styles/core.scss';

.body {
  padding: $space * 4;
  position: relative;
  z-index: $z-index-pagebody;

  @include media('<tablet') {
    padding: ($space * 4) $space;
  }

  @include media('<phone') {
    padding: ($space * 4) ($space * 2);
  }
}
