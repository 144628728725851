.container {
  display: grid;
  grid-template-columns: 1.1fr 0.9fr;
  color: var(--r-color-gray-120);
}

.container.mobile {
  grid-template-columns: 1fr;
}

.fields-config {
  padding-bottom: 3rem;
}

.mobile-header {
  padding: 1.25rem 0;
  display: flex;
  justify-content: flex-end;
}

.left-column {
  padding: 2rem;
  display: grid;
  justify-content: center;
  grid-template-rows: 90px auto;
}

.header {
  max-width: 680px;

  button {
    padding: 0;
  }
}

.header.mobile {
  max-width: 100%;

  button {
    padding: 0 1.5rem;
  }
}

.right-title {
  display: flex;
  justify-content: space-between;

  button {
    padding-right: 0;
  }
}

.lead-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 680px;
}

.right-column {
  padding: 3rem;
  background: var(--r-color-white, #fff);
  border-left: 1px solid var(--r-color-gray-40, #dfdfe1);
  position: relative;
  height: 100%;
  h2 {
    padding-bottom: 1.5rem;
  }
}

.right-column.mobile {
  padding: 0 3rem 3rem;
}

.field-container {
  padding-bottom: 2.5rem;
  display: grid;
  gap: 1rem;
}

.modal {
  padding: 0;
}

.gray120 {
  color: var(--r-color-gray-120);
}

.gray100 {
  color: var(--r-color-gray-100);
}

.brand-container {
  padding-bottom: 1.5rem;
}

.footer {
  height: 84px;
  width: calc(100% - 56px);
  display: grid;
  grid-template-columns: 1.1fr 0.9fr;
  position: fixed;
  right: 0;
  bottom: 0;
}

.footer-mobile {
  grid-template-columns: auto;
  width: 100%;
}

.footer-buttons {
  display: flex;
  background: var(--r-color-white, #fff);
  padding: 0.5rem;
  border-top: 1px solid var(--r-color-gray-40, #dfdfe1);
  border-left: 1px solid var(--r-color-gray-40, #dfdfe1);
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: var(--r-color-gray-120);
}

.rightblock {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
}
