.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  gap: 0.125rem;
}

.button {
  all: unset;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  text-align: center;
  border: 1px solid transparent;
  cursor: pointer;

  &:hover {
    background-color: var(--r-color-gray-20);
  }

  &:active {
    background-color: var(--r-color-gray-40);
  }

  &:disabled {
    color: var(--r-color-gray-60);
  }
}

.selected {
  cursor: not-allowed;
  border-color: var(--r-color-gray-90);

  &:focus,
  &:hover {
    background-color: transparent;
  }
}
