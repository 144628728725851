html,
body {
  height: 100%;
}

.iframe-container {
  height: 100%;
}

iframe {
  width: 100%;
  height: 100%;
}
