.group {
  color: var(--r-color-gray-70);
}

.counter {
  margin-top: 1rem;
}

.error {
  color: var(--r-color-negative);
}
