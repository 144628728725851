.container-border {
  border: 1px solid var(--r-color-gray-40);
  border-radius: 0.5rem;

  &:focus,
  &:hover,
  &:active {
    border-width: 0;
  }
}

.container {
  display: grid;
  padding: 0.5rem 1rem;
  border: 1px solid transparent;
  border-radius: 0.5rem;
  gap: 2rem;

  &:focus,
  &:hover,
  &:active {
    border: 2px solid var(--r-color-blue-60);

    .chevron {
      color: var(--r-color-blue-60);
    }
  }
}

.header {
  display: grid;
  grid-template-columns: auto 60px;
  color: var(--r-color-gray-100);
  gap: 0.5rem;
  align-items: center;

  button:not(.title-button button) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    gap: 1rem;
    color: var(--r-color-gray-120);

    &:hover,
    &:focus,
    &:active {
      color: var(--r-color-gray-120);
      text-decoration: none;
    }
  }

  .title-button {
    display: flex;
    justify-content: flex-end;

    button {
      padding: 0;
    }
  }
}

.icon {
  height: 24px;
}
