@import '_styles/core.scss';

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: $space * 3;
}

.info,
.statistic {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-container {
  width: $space * 5.25;
  margin-right: $space * 1.5;
}

.title {
  @include FontSemiBold;
  @include font-small;
  padding-bottom: $space;
}

.delta {
  @include FontSemiBold;
  @include font-small;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  padding: $space * 0.5;
  border-radius: 18px;
}

.neutral {
  color: $gray50;
  background: rgba(155, 155, 155, 0.2);
}

.positive {
  color: $positive;
  background: rgba(1, 178, 163, 0.2);
}

.negative {
  color: $negative;
  background: rgba(207, 0, 15, 0.2);
}

.value {
  width: $space * 5;
  margin-right: $space * 3;
}
