@import '_styles/core.scss';

.banner {
  background: #d1287a;
  color: $white;
  padding: $space * 3 $space * 11.75;
  display: flex;
  flex-direction: column;
}

.body {
  margin-bottom: $space * 3;
  padding-left: $space * 3;
  padding-right: $space * 3;
}

.avatar {
  flex: 0 0 auto;
  margin-right: $space * 3;
  border-radius: 50%;
  box-shadow: 0 0 0 4px $white;
  height: $space * 6;
  width: $space * 6;
}

.quote {
  @include spacingReset;
  @include font-large;
  @include FontSemiBold;
  &:before {
    content: open-quote;
  }
  &:after {
    content: close-quote;
  }
}

.cite {
  margin-top: $space;
}

.mobile {
  padding: $space * 4;
}
