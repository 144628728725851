@import '_styles/core.scss';

.container {
  flex: 0 0 (5 * $space);
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &.vendor-search {
    height: 100%;
  }
}

.title {
  display: flex;
  align-items: center;
  color: $white;
  padding: $space * 2;
  min-height: 7 * $space;

  @include media('>tablet') {
    margin-left: 0;
    margin-right: 0;
    padding: $space $space;
    border-bottom: 1px solid $gray30;
    color: $textblackprimary;
  }
}

.name {
  width: 21.25 * $space;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.logo {
  width: 5 * $space;
  height: 5 * $space;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
}

.link {
  @include font-large;
  @include FontBold;
  flex: 1 0 auto;
  display: flex;
  margin-left: $space;
  color: currentColor;
  justify-content: space-between;
  align-items: center;
}

.results {
  min-height: 0;
  display: flex;
  flex-direction: column;
}

.vendor-switcher-results {
  flex: 1;
  overflow-y: auto;
  padding: 0 2 * $space;
}

.search-wrapper {
  padding: 0 (2 * $space);
}

.search {
  input:focus + label svg {
    color: $TRblue;
  }
  svg {
    color: $gray70;
  }
}

.separator {
  border-top: 1px solid $gray40;
  margin-bottom: 2.5 * $space;
}
