@import '_styles/core.scss';

.page {
  min-height: 100%;
  background-color: $backgroundgray;

  &.loading {
    opacity: 0.25;
    transition: opacity 300ms 200ms linear;
  }
}

.body-container {
  box-sizing: content-box;
  max-width: 1000px;
  padding: 3 * $space;

  label {
    @include FontSemiBold;
  }
}
