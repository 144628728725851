@import '_styles/core.scss';

.page-body {
  display: grid;
  grid-template-columns: 732px 400px;
  grid-template-areas: 'main aside';
  align-items: start;
  gap: 3rem;

  a {
    &:hover {
      text-decoration: underline;
    }
  }
  section {
    h3 {
      @include h5;
    }
  }

  @include media('>phone', '<=tablet-xl') {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    grid-template-areas: 'aside' 'main';
    gap: 2.5rem;
    padding: 0 1.5rem;
    height: calc(100vh - 166px);
    overflow-y: scroll;
  }
}

.separator {
  border-top: solid 1px var(--r-color-gray-30);
}

.aside {
  grid-area: aside;
}

.features {
  border: 1px solid var(--r-color-gray-30);
  border-radius: var(--r-radius-lg);
  padding: 0 2rem;
  margin-bottom: 2rem;
}

.features,
.slider {
  .icon {
    color: var(--r-color-gray-90);
  }

  a {
    &:hover {
      text-decoration: underline;
    }
  }

  section {
    padding: 2rem 0;

    button {
      padding: 0;
      margin: 0;
      border: none;
      width: 100%;
      display: flex;

      @include media('>phone', '<=tablet') {
        cursor: pointer;
        padding-bottom: 1.5rem;
      }
    }

    h3 {
      @include h5;
      @include media('>phone', '<=tablet') {
        display: flex;
        align-items: center;
        gap: 0.875rem;
      }
    }

    ul {
      padding-left: 1.5rem;
      margin: 1.5rem 0 0;

      &.no-padding {
        padding: 0;
      }

      @include media('>phone', '<=tablet') {
        padding-left: 1.5rem;
      }
    }

    a {
      text-decoration: none;
    }

    .text {
      @include media('>phone', '<=tablet') {
        margin-top: 1rem;
      }
    }
  }

  @include media('>phone', '<=tablet') {
    padding: 0;
    border: none;

    section {
      padding: 1.5rem 0 0 0;
    }
  }
}

.slider-title {
  margin-bottom: 1.5rem;
}

.slider {
  section {
    padding-top: 0;
  }
}

.no-padding {
  list-style-type: none;
  margin: 0;

  li {
    display: flex;
    margin-bottom: 1rem;

    svg {
      flex-shrink: 0;
      margin-right: 1.5rem;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.tags {
  @include media('>phone', '<=tablet-xl') {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;

    li {
      margin: 0;
      text-wrap: nowrap;
      display: inline;
    }
  }
}

.main {
  grid-area: main;
  padding-bottom: 2rem;
}

.content {
  padding: 2rem 3rem 0;

  section {
    margin-top: 1.5rem;

    &:first-child {
      margin-top: 0;
      padding-top: 0;
    }
  }

  h2 {
    @include font-xxLarge;
    @include FontNormal;
  }

  h3 {
    margin-top: 1rem;
    @include font-medium;
    @include FontBold;
  }

  p + p {
    margin-top: 1rem;
  }

  ul {
    margin-top: 0;
    padding-left: 1.5rem;
  }

  @include media('>phone', '<=tablet-xl') {
    padding: 3rem 0 1.5rem;
  }
}

.section-content {
  &.dates {
    display: flex;

    .dates-info {
      display: flex;
      flex-direction: column;

      :nth-child(1) {
        @include FontSemiBold;
      }
    }
  }

  ul {
    padding-left: 1.25rem;
    margin: 0;
  }
}

.mobile-view {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background: radial-gradient(
      82.55% 82.55% at 50% 50%,
      #ffffff 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    linear-gradient(to top, #a3deff -60%, #f5fbff 50%);

  h2 {
    margin-top: 3rem;
    @include font-xLarge;
  }

  p {
    max-width: 17.5rem;
    text-align: center;
    margin: 0.5rem 0 1.5rem;
  }

  .link {
    width: fit-content;
  }
}
