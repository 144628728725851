@import '_styles/core.scss';

.pill {
  @include font-small;
  @include FontSemiBold;
  padding: $space $space * 1.5;
  box-shadow: inset 0 0 0 2px transparentize($gray60, 0.8);
  display: inline-flex;
  border-radius: 32px / 100%;
  background: $white;
  align-items: center;

  @include media('>phone') {
    @include font-medium;
    padding: $space * 0.625 $space * 1.5;
    border-radius: 32px / 100%;
  }

  svg {
    flex: 0 0 auto;
  }

  > * + * {
    margin-left: $space / 2;
  }
}
