@import '_styles/core.scss';

.backdrop:global(.trModal-backdrop) {
  display: block;
}

.modal {
  .dialog:global(.modal-dialog) {
    max-width: 550px;
    min-height: calc(100vh - 3.5rem); // removing margins from modal styles
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .header {
    background-color: transparent;
    align-items: flex-start;

    .title {
      flex: 0 400px;
      color: $textblackprimary;
    }

    :global(.close) {
      width: 32px;
      height: 32px;
      border: none;
    }
  }

  .body {
    padding-top: 0.5 * $space;
    padding-bottom: 0.5 * $space;

    .error-message {
      margin-top: 0.5 * $space;
      color: $negative;
    }
  }

  .footer {
    box-shadow: none;

    .cancel-button {
      border-color: $gray30;
      margin-right: $space;
    }
  }
}
