@import '_styles/core.scss';

.container {
  margin-top: $space * 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.wrapper {
  width: 50%;
  background: $gray10;
  padding: $space * 6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.header {
  margin-top: $space * 2;
}

.text,
.header {
  @include FontNormal;
  text-align: center;
  color: $gray90;
}

.button {
  background: $TRblue;
  border: 1px solid $TRblue;
  border-radius: 100px;
  color: $white;
  width: 240px;
  cursor: pointer;
  padding: $space;
  margin: $space * 3;
  text-align: center;

  &:hover {
    text-decoration: none;
  }
}

.link {
  text-decoration: none;
  color: $TRblue;
}
