@mixin button-structure {
  // reset possible defaults
  cursor: pointer;
  text-transform: none;
  overflow: visible;
  text-shadow: none;
  box-shadow: none;
  display: inline-block;
  -webkit-appearance: none;
  min-height: $space * 4.5;
  line-height: 1;
  transition: color 0.1s ease-in, border-color 0.1s ease-in,
    background-color 0.1s ease-in;
  text-align: center;
  font-weight: 600;
  border: 1px solid;
  border-color: $gray40;
  border-radius: $space/2;
  padding: $space ($space * 2);

  &:focus,
  &:hover {
    text-decoration: none;
  }
}

@mixin button-large {
  height: $space * 6;
  font-size: 19px;
  padding: $space * 1.5 $space * 2;
  min-width: $space * 30;
}

@mixin button-editorial {
  color: $white;
  background-color: $plum;
  border-color: $plum;

  &:hover {
    color: $white;
    background-color: darken($plum, 5%);
    border-color: $darkplum;
  }

  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: $pastelblue auto 5px;
  }

  &:active {
    background-color: $darkplum;
    border-color: $darkplum;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: $black;
    border-color: $black;
    color: $white;
  }
}
