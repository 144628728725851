@import '_styles/core.scss';

.container {
  border-radius: 0.5rem;
  border: 1px solid var(--r-color-gray-40);
}

.content-container {
  padding: 1.5rem 2rem;
}

.metrics {
  display: flex;
  justify-content: space-between;
  margin: 2rem 0;
  align-items: flex-start;

  :nth-child(2) {
    width: 113px;
  }

  :nth-child(3) {
    width: 107px;
  }
}

.metric {
  width: 85px;
  display: flex;
  flex-direction: column-reverse;
}

.metric-title {
  color: var(--r-color-gray-100);
}

.url {
  a {
    padding: 0;
  }
}

#prevButton,
#nextButton {
  width: 24px;
  color: var(--r-color-blue-60);
  cursor: pointer;

  &:disabled {
    color: var(--r-color-gray-90);
    cursor: not-allowed;
  }
}

.swipe-buttons {
  display: flex;
  margin-top: 1.5rem;
  justify-content: space-between;
  align-items: center;
  width: 150px;
  margin: 1.5rem auto;
}

#pagination {
  transform: translateY(0);
}
