@import '_styles/core.scss';

.container {
  &:first-of-type {
    margin-top: 3rem;
  }

  & + & {
    margin-top: 2rem;
  }

  p,
  ul {
    color: var(--r-color-gray-100);
  }
}

.header {
  display: flex;
  gap: 2rem;
}

.header-body {
  flex: 1 1 auto;
}

.header-action {
  flex: 0 0 auto;
}

.heading {
  margin: 0;

  & + p {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
  }
}

.error {
  margin-top: 0.5rem;
  color: var(--r-color-negative);
}

.sr-only {
  @include sr-only;
}
