.counter {
  display: flex;
  justify-content: flex-end;
  color: var(--r-color-gray-70);
}

.content {
  align-items: center;

  button {
    width: 100%;
    justify-content: center;
  }
}

.item {
  &:hover {
    background: var(--r-color-blue-60);
    color: #ffffff;
  }
}

.body {
  width: 100%;
  div {
    width: 100%;
  }
}

.input-item {
  margin-top: 1rem;
  button {
    justify-content: space-between;
  }
}

.error {
  color: var(--r-color-negative);
}
