@import '_styles/core.scss';

.header {
  box-shadow: 0 2px 14px rgba(0, 0, 0, 0.1);

  @include media('>tablet') {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.title-block {
  @include media('<=tablet') {
    padding: (2 * $space) (3 * $space);
    border-bottom: 1px solid $gray20;
  }

  @include media('>tablet') {
    padding: 4 * $space;
  }

  h1 {
    margin-bottom: 0.5 * $space;

    @include media('<=tablet') {
      @include h3;
    }

    @include media('>tablet') {
      @include h2;
    }
  }
}

.subtitle {
  color: $textlight;
}

.switch-block {
  flex-shrink: 0;

  @include media('<=tablet') {
    padding: 3 * $space;
  }

  @include media('>tablet') {
    padding: 4 * $space;
  }
}

.switch-label {
  @include FontSemiBold;
  color: $textblackprimary;
}

.page-body {
  margin-left: auto;
  margin-right: auto;
  box-sizing: content-box;
  max-width: 680px;

  @include media('<=tablet') {
    max-width: 550px;
    padding-left: 3 * $space;
    padding-right: 3 * $space;
  }

  section + section {
    margin-top: 3 * $space;
  }
}

.section-title {
  @include font-xLarge;
  @include FontSemiBold;
  margin-bottom: $space;
}

.section-text {
  color: $gray80;
}

.modal-replica-container {
  margin-top: 2 * $space;
  background-color: white;
  border-radius: $space;
  overflow: hidden;
  filter: drop-shadow(0px 1px 32px rgba(0, 0, 0, 0.15));
  position: relative;
}

.modal-close-button {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  padding: 2 * $space;
}

.eligible-products-button {
  @include FontSemiBold;
  margin-top: 2 * $space;
  border: none;
  padding: 0;
  color: $TRblue;
  cursor: pointer;
}

.popover {
  :global(.tr-popover-body) {
    @include FontSemiBold;
    @include font-small;
    line-height: 22px;
    background: $black;
    color: $white;
    border-radius: $popover-border-radius;
  }
  :global(.arrow) {
    &::after {
      border-bottom-color: $black !important;
    }
  }
}
