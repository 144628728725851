.fade {
  transition: opacity 0.15s linear;
  opacity: 0;
}

.show {
  opacity: 1;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
