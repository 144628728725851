.rightColumn {
  min-height: 77vh;
  padding: 1rem 2rem 2.5rem;
  border-left: 1px solid var(--r-color-gray-40);
  p {
    color: var(--r-color-gray-120);
  }

  button {
    padding: 0;
    margin-top: 0.5rem;
  }

  h2 {
    margin-bottom: 1rem;
    margin-top: 1.5rem;
  }
}

.product-list {
  margin-top: 1.5rem;
}

.product {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;

  img {
    margin-right: 0.5rem;
    width: 30px;
    max-height: 30px;
  }
}
