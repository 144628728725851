@import '_styles/core.scss';

.toast {
  :global(.close) {
    border: none;
  }

  :global(.sr-only) {
    @include sr-only;
  }
}
