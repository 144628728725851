@import '_styles/core.scss';

.container {
  color: $textblackprimary;
  width: 57.5 * $space;
  padding: 0 (4 * $space) (6 * $space);

  @include media('<desktop') {
    width: 100%;
    padding: 0 0 (6 * $space) (4 * $space);
  }

  @include media('<tablet-lg') {
    padding: 0 (2 * $space) (4 * $space);
  }

  @include media('<phone') {
    padding: 0 0 (4 * $space);
  }
}

.button {
  border: none;
  padding: 0;

  svg {
    margin-right: 2 * $space;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: $space * 1.5;
  border-bottom: 1px solid $gray30;
}

.title {
  @include FontBold;
  @include font-medium;
  text-transform: capitalize;

  @include media('>=tablet-lg') {
    @include font-xLarge;
  }
}

.children {
  padding-top: $space * 1.25;
}
