@import '_styles/core.scss';

.container {
  position: relative;
  display: flex;
  align-items: center;
}

.button {
  background-color: $gray60;
  border-radius: 50%;
  height: 4 * $space;
  width: 4 * $space;
  padding: 0;
  margin: 0;
  border: 0;
}

.menu {
  position: absolute;
  top: 100%;
  right: 0;
  width: max-content;
  min-width: 22.5 * $space;
  max-width: 31.25 * $space;
  box-shadow: $box-shadow;
  background-color: $white;
  z-index: $zindex-dropdown;
  display: flex;
  flex-direction: column;
}

.nav {
  padding: $space * 2;
  display: flex;
  flex-direction: column;
}

.link {
  text-decoration: none;
  color: $textblackprimary;
  padding: ($space * 0.5) 0;
}

.logout {
  border: none;
  border-top: 1px solid $gray30;
  padding: $space * 2;
  text-align: left;
  cursor: pointer;
}
