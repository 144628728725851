.table-container {
  border: 1px solid var(--r-color-gray-20);
  border-radius: 0.5rem;
}

.search {
  position: relative;

  .search-icon {
    width: 1rem;
    color: var(--r-color-gray-80);
    position: absolute;
    top: 6px;
    left: 1rem;
  }

  input {
    width: 300px;
    color: var(--r-color-gray-80);
    padding-left: 2.2rem;
    border-radius: 100px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  padding: 1.5rem;
}

.copy-button {
  button {
    padding: 0;
  }
}

.table {
  width: 100%;
}

.table-head {
  text-align: left;
  border-top: 1px solid var(--r-color-gray-20);
  border-bottom: 1px solid var(--r-color-gray-20);
  background: var(--r-color-gray-10);

  th {
    width: 50%;
    padding: 0.75rem 1.5rem;
    color: var(--r-color-gray-90, #636364);
  }
}

.tr {
  border-bottom: 1px solid var(--r-color-gray-20, #f0f0f2);
}

.td {
  padding: 1rem 1.5rem;
}

.pagination-container {
  padding: 1.5rem 0 1rem;
}
