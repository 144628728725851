@import '_styles/core.scss';

.banner {
  background-image: url('./assets/Banner.png');
  background-size: cover;
  color: $white;
  padding: $space * 5.75 $space * 5.75;
  display: grid;
  grid-template-columns: minmax(0, max-content) minmax(0, auto);
  gap: $space * 5.75;
}

.mobile {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.body {
  margin-bottom: $space * 3;
  padding-left: $space * 3;
  padding-right: $space * 3;
}

.super {
  font-size: (56/16) + rem;
  line-height: 1;
  @include FontBold;
}

.text {
  margin: 0;
  @include FontSemiBold;
  max-width: 300px;
}

.logos {
  display: flex;
  align-self: center;

  > .logo-container + .logo-container {
    margin-left: -$space * 2;
  }
  .logo-container:nth-child(1) {
    max-width: 47px;
    max-height: 47px;
    z-index: 3;
  }

  .logo-container:nth-child(2) {
    margin-top: $space * 3;
    max-width: 57px;
    max-height: 57px;
    z-index: 2;
  }

  .logo-container:nth-child(3) {
    max-width: 57px;
    max-height: 57px;
  }

  .logo-container:nth-child(4) {
    max-width: 57px;
    max-height: 57px;
    z-index: 2;
    margin-top: $space * 2.5;
  }

  .logo-container:nth-child(5) {
    margin-top: 7 * $space;
    z-index: 3;
    max-width: 47px;
    max-height: 47px;
  }
}

.logo-container {
  flex: 0 0 auto;
  position: relative;
  background-color: $white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: $border-radius;
  overflow: hidden;

  img {
    object-fit: cover;
    border-radius: $border-radius;
    width: 100%;
    height: 100%;
  }
}

.banner-content {
  display: flex;
  flex-direction: column;

  &.no-companies {
    flex-direction: row;
    align-items: center;
  }
}

.no-companies {
  .super {
    margin-right: 3 * $space;
  }
}
