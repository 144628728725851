@import '_styles/core.scss';

.body {
  padding: 0 $space * 3;
}

.heading {
  @include font-large;
  margin-bottom: $space;
}

.media {
  display: flex;
  align-items: center;

  ul {
    @include font-small;
    margin-left: $space * 5.25;
  }

  li + li {
    margin-top: $space * 1.5;
  }
}

.mobile {
  flex-direction: column;
  align-items: flex-start;

  img {
    width: 128px;
    display: inline-block;
    padding: 3 * $space 0;
  }

  ul {
    padding-left: $space * 2;
    margin: 0;
  }

  svg {
    margin-top: $space * 3;
    margin-bottom: $space * 3;
  }
}
