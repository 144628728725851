@import '_styles/core.scss';

.container {
  @include media('>tablet') {
    display: grid;
    grid-template-columns: 56px minmax(0, auto);
    overflow: hidden;
    min-height: 100%;
  }
}
