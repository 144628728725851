@import '_styles/core.scss';

.trModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  outline: 0;
  display: none;
  z-index: $zindex-modal;
  overflow-x: hidden;
  overflow-y: auto;

  .modal-dialog {
    pointer-events: none;
    max-width: 600px;
    margin: 1.75rem auto;
  }
  .modal-content {
    position: relative;
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
    border: none;
    background-color: $white;
    background-clip: padding-box;
    outline: 0;
    pointer-events: auto;
    border-radius: $border-radius-lg;
  }

  &.show {
    opacity: 1;

    .modal-dialog {
      transform: translateY(0);
    }
  }
}

.trModal-backdrop {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: $zindex-modal-backdrop;

  &.show {
    opacity: 1;
  }
}

.trModal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $space * 1.5 $space * 2.5;
  background-color: $gray30;
  border-radius: $space $space 0px 0px;
  overflow: hidden;

  button.close {
    @include dismissButton;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  .trModal-danger & {
    background-color: $negativefade;
  }

  .trModal-warning & {
    background-color: $warningfade;
  }

  &.-highlight-header {
    background: $white;
    position: relative;
    display: block;

    button.close {
      position: absolute;
      top: $space;
      right: $space * 2;
    }
  }

  &.-lead-header {
    background: linear-gradient(180deg, #141b2b 0%, #2b3758 100%);
    border-radius: ($space * 0.5) 0 0 ($space * 0.5);
    padding: ($space * 3) ($space * 4);
    display: block;
    flex-direction: column;
  }
}

.trModal-title {
  padding: 0;
  margin: 0;
  font-weight: 600;
  color: $gray80;
  flex: 1 0 auto;
  padding-right: $space * 2.5;

  .trModal-danger &,
  .trModal-warning & {
    background-repeat: no-repeat;
    background-position: left center;
    padding-left: $space * 4;
  }

  .trModal-danger & {
    color: $negative;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23ED3755' stroke='%23ED3755' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M10.29,3.86,1.82,18a2,2,0,0,0,1.71,3H20.47a2,2,0,0,0,2-2,2.09,2.09,0,0,0-.27-1L13.71,3.86A2,2,0,0,0,11,3.19,2,2,0,0,0,10.29,3.86Z'/%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M12 9L12 13M12 17L12 17'/%3E%3C/svg%3E");
  }

  .trModal-warning & {
    color: $warningchrome;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23ff934a' stroke='%23ff934a' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M10.29,3.86,1.82,18a2,2,0,0,0,1.71,3H20.47a2,2,0,0,0,2-2,2.09,2.09,0,0,0-.27-1L13.71,3.86A2,2,0,0,0,11,3.19,2,2,0,0,0,10.29,3.86Z'/%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M12 9L12 13M12 17L12 17'/%3E%3C/svg%3E");
  }
}

.trModal-body {
  background: $white;
  padding: $space * 4 $space * 2.5;
}

.trModal-footer {
  padding: $space * 1.5 $space * 2.5;
  box-shadow: inset 0 1px 0 0 $gray20;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-radius: $space $space 0px 0px;
  overflow: hidden;

  button.button + button.button {
    margin-left: $space * 4;
  }

  .button-link {
    color: $TRblue;
    text-decoration: none;

    &[disabled] {
      color: $textlight;
      background: none;

      &:hover {
        color: $textlight;
      }
    }

    &:hover {
      color: $TRblue;
      text-decoration: none;
    }
  }
}

@include media('<=tablet') {
  .trModal-header {
    &.-lead-header {
      padding: ($space * 4.5) ($space * 3) ($space * 3);
      border-radius: 0 0 0 0;
    }
  }
}

.trModal-panel {
  background-color: $gray30;
  padding: $space * 2.5;
  margin-left: -$space * 2.5;
  margin-right: -$space * 2.5;
  margin-top: -$space * 4;
}
