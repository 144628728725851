.header {
  h2,
  p {
    margin: 0;
  }
}

.product-selection {
  display: grid;
  gap: 1rem;
  padding-left: 1rem;
  padding-top: 1rem;

  h3 {
    margin: 0;
  }
}

.all label {
  font-weight: 600;
}

.gray100 {
  color: var(--r-color-gray-100);
}
